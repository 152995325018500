<!-- 
<ion-content fullscreen>
  <div class="h-full relative bg-teal-100 text-gray-800">
    <div class="p-2 absolute top-0 right-0 text-white font-semibold text-lg z-10">
      <button class="px-4 py-2 rounded bg-gray-400 bg-opacity-75 shadow-md" (click)="closeModal()">
        CLOSE
      </button>
    </div>
    <div class="p-2 absolute top-0 left-0 text-white font-semibold text-lg z-10">
      Room: {{ room }}
    </div>
    <div class="w-full h-full bg-yellow-400">
      <video [ngIf]="stream" #video class="w-full h-full" controls autoplay playsinline [muted]="true"></video>
    </div>
  </div>
</ion-content> -->
<custom-modal id="video-call-modal">
    <div class="modal width-70">
        <div class="modal-body newmodalbody" [ngClass]="{'errorModal': errorMessageVideo && errorMessageVideo.length > 0}" >
            <div class="call-box incoming-box">
                <div class="call-wrapper">
                    <div class="call-inner">
                        <!-- <div>
                            <div class="call-user">
                               
                                <h4><span> {{callerName}}</span>
                                </h4>
                            </div>
                            <div class="timer">
                                <p>{{timerHours | number:'2.0'}} : {{timerMinutes | number:'2.0'}} : {{timerSeconds |
                                    number:'2.0'}}</p>
                            </div>
                        </div> -->
                        <div id="main" class="super_container" *ngIf="errorMessageVideo.length == 0">
                            <div id="wrapper">
                                <div class="content full-height">
                                    <div class="home-main_container">
                                        <div class="media-container media-container_fs">
                                            <div class="video-holder">
                                                <div class="video-container">
                                                    <div class="remote remote-frame">
                                                        <video id="remoteVideo" #remoteVideo class="remote-frame"
                                                            autoplay playsinline loop muted="false">
                                                        </video>
                                                        <audio class="hide" id="remoteAudio" #remoteAudio autoplay
                                                            playsinline></audio>
                                                        <div class="local local-frame">
                                                            <video id="localVideo" #localVideo class="local-frame"
                                                                autoplay playsinline muted="true"></video>
                                                        </div>
                                                        <div class="info">
                                                            <p><span class="red">*</span> Please wait until you see the
                                                                video</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="call-user mt-2">
                               
                                                <h4><span> {{callerName}}</span>
                                                </h4>
                                            </div>
                                            <!-- <div class="timer">
                                                <p>{{timerHours | number:'2.0'}} : {{timerMinutes | number:'2.0'}} : {{timerSeconds |
                                                    number:'2.0'}}</p>
                                            </div> -->
                                            <div class="call-items">
                                             

                                                <a href="javascript:void(0)" *ngIf="speakerOnButtonDisplay"
                                                    (click)="speakerOff()" class="btn call-item speaker">
                                                    <span class="material-icons">volume_up</span>
                                                </a>
                                                <a href="javascript:void(0)" *ngIf="speakerOffButtonDisplay"
                                                    (click)="speakerOn()" class="btn call-item speaker">
                                                    <span class="material-icons">volume_off</span>
                                                </a>
                                                <a href="javascript:void(0)" (click)="stopVideoCall()"
                                                    class="btn call-item call-end">
                                                    <span class="material-icons">close</span>
                                                </a>
                                                <a href="javascript:void(0)" *ngIf="unmuteButtonDisplay"
                                                    (click)="mute()" class="btn call-item mute">
                                                    <span class="material-icons">mic</span>
                                                </a>
                                                <a href="javascript:void(0)" *ngIf="muteButtonDisplay"
                                                    (click)="unMute()" class="btn call-item mute">
                                                    <span class="material-icons">mic_off</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="errorMessageVideo && errorMessageVideo.length > 0">
                            <div class="error mt-2 mb-3">
                                {{errorMessageVideo}}
                            </div>
                            <div class="call-items">
                                <a class="btn call-item call-end" (click)="closeVideoModal()">
                                    <span class="material-icons">close</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-background"></div>
</custom-modal>

<custom-modal id="accept-reject-call-modal">
    <div class="modal width-70">
        <div class="modal-body">
            <div class="call-box incoming-box">
                <div class="call-wrapper">
                    <div class="call-inner">
                        <h5 class="text-white"><span>{{callerName}} </span> Calling
                        </h5>
                        <div class="avatar height-150 width-100">
                            <div class="callavtar letter-avatar center">
                                {{getFirstLastLetterFromFullName(callerName)}}
                            </div>
                        </div>
                        <div class="call-items">
                            <a href="javascript:void(0)" (click)="rejectVideoCall()" class="btn call-item call-end">
                                <span class="material-icons">close</span>
                            </a>
                            <a href="javascript:void(0)" (click)="acceptVideoCall()" class="btn call-item call-start">
                                <i class="fas fa-video"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-background"></div>
</custom-modal>

<custom-modal id="audio-call-modal">
    <div class="modal width-50 l-25">
        <div class="modal-body">
            <div class="call-box incoming-box">
                <div class="call-wrapper">
                    <div class="call-inner">
                        <div>
                            <div class="call-user">
                                <div class="">
                                    <h4><span>{{callerName}} </span></h4>
                                </div>
                            </div>
                            <!-- <div class="timer">
                                <p>{{timerHours | number:'2.0'}} : {{timerMinutes | number:'2.0'}} : {{timerSeconds |
                                    number:'2.0'}}</p>
                            </div> -->
                        </div>
                        <div class="avatar height-150 width-100">
                            <div class="letter-avatar center">
                                {{getFirstLastLetterFromFullName(callerName)}}
                            </div>
                        </div>
                        <div class="call-items">
                            <a href="javascript:void(0)" *ngIf="audioSpeakerOnButtonDisplay" (click)="audioSpeakerOff()"
                                class="btn call-item speaker">
                                <span class="material-icons">volume_up</span>
                            </a>
                            <a href="javascript:void(0)" *ngIf="audioSpeakerOffButtonDisplay" (click)="audioSpeakerOn()"
                                class="btn call-item speaker">
                                <span class="material-icons">volume_off</span>
                            </a>
                            <a href="javascript:void(0)" (click)="closeAudioCall()" class="btn call-item call-end">
                                <span class="material-icons">close</span>
                            </a>
                            <a href="javascript:void(0)" *ngIf="unmuteAudioButtonDisplay" (click)="muteAudio()"
                                class="btn call-item mute">
                                <span class="material-icons">mic</span>
                            </a>
                            <a href="javascript:void(0)" *ngIf="muteAudioButtonDisplay" (click)="unmuteAudio()"
                                class="btn call-item mute">
                                <span class="material-icons">mic_off</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-background"></div>
</custom-modal>
<div class="main-wrapper">

    <div class="content main_content">
        <chat-sidebar-menu [selectedTab]="selectedTab" [loggedInUser]="loggedInUser"></chat-sidebar-menu>

        <div class="sidebar-group left-sidebar chat_sidebar">

            <div class="min-height-100">
                <div id="chats" class="left-sidebar-wrap sidebar active ">
                    <div class="">
                        <div class="">

                            <div class="left-chat-title d-flex justify-content-start align-items-center">
                                <!-- <div class="me-2"> <a (click)="navigation.back()" class="clr_blue"><span><i
                                                class="fa fa-arrow-left" aria-hidden="true"></i></span></a></div> -->

                                <div class="chat-title  mb-2 margin-top-15">
                                    <h4 class="main-heading-new">CONNECTIONS</h4>
                                </div>
                            </div>

                            <div class="search_chat has-search mb-0">
                                <span class="fas fa-search form-control-feedback"></span>
                                <input class="form-control chat_input" type="text" placeholder="Search Connection"
                                    [(ngModel)]="searchTerm">
                            </div>

                            <div class="sidebar-body" id="chatsidebar">
                                <div class="scrollbar" id="scrollbar8">
                                    <ul class="user-list mt-2" *ngIf="connections && connections.length > 0">
                                        <li class="user-list-item"
                                            *ngFor="let user of connections | connectionSearchFilter: searchTerm"
                                            (click)="setSelected(user)" [class.pending]="user.status == 'Requested'"
                                            data-bs-toggle="modal" data-bs-target="#addContactConfirm">
                                            <div *ngIf="!isEmpty(user.img)" class="avatar">
                                                <img [src]="user.img" class="rounded-circle" alt="image">
                                            </div>
                                            <div *ngIf="isEmpty(user.img)" class="avatar"
                                                [class.avatar-away]="!user.online && !user.dnd"
                                                [class.avatar-online]="user.online && !user.dnd"
                                                [class.avatar-offline]="user.dnd">
                                                <div class="letter-avatar">
                                                    {{getFirstLastLetter(user)}}
                                                </div>
                                            </div>
                                            <div class="users-list-body">
                                                <div>
                                                    <h5 appHighlight [content]="user.firstName + ' ' + user.lastName"
                                                        [searchTerm]="searchTerm" [caseSensitive]="false"></h5>
                                                    <p appHighlight
                                                        [content]="user.city + ' ' + user.state + ' ' + user.country"
                                                        [searchTerm]="searchTerm" [caseSensitive]="false"></p>
                                                </div>
                                                <div class="last-chat-time" *ngIf="user.chatInitiated">
                                                    <img src="assets/images/tick-16.png" alt="tick" />
                                                </div>
                                                <div class="last-chat-time" *ngIf="user.status == 'Requested'">
                                                    <small class="pending-text">Pending</small>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <div *ngIf="!connections || connections.length == 0">
                                        <img class="noRecentChantimg" src="assets/images/empty.svg" />
                                        <div class="chat-title">
                                            <h4 class="mb-4 mt-4 text-center startChat noconnectheading">No connection found</h4>
                                            <!-- <h4 class="mb-4 mt-4 text-center startChat">Chat, call with your connections with high security make connections</h4> -->
                                          </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>


        <div class="chat status-middle-bar d-flex align-items-center justify-content-center">
            <div class="status-right">
                <div>
                    <img *ngIf="connections && connections.length >  0" class="nodata2" src="assets/images/nodata2.png" alt="">
                    <img *ngIf="!connections || connections.length == 0"  class="nodata2" src="assets/images/nodata2.png" />
                </div>
                <div>
                    <p *ngIf="connections && connections.length > 0">Click on a contact to add user for chat</p>
                    <p *ngIf="!connections || connections.length == 0">Looks like you dont have connections</p>
                    <div class="mt-3" *ngIf="!connections || connections.length == 0">
                        <button class="btn btn-update btn-signin" (click)="goToDirectory()"><i class="fa fa-plus" aria-hidden="true"></i>&nbsp; Create Connection</button>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="modal fade" id="addContactConfirm">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" *ngIf="!selectedConnection.chatInitiated">
                        Add to Chat Member
                    </h5>
                    <h5 class="modal-title" *ngIf="selectedConnection.chatInitiated">
                        Already Exsit
                    </h5>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                        <span class="material-icons">close</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="success-icon delete-tab text-center">
                        <p *ngIf="!selectedConnection.chatInitiated">Are you sure want to add this user to chat?</p>
                        <p *ngIf="selectedConnection.chatInitiated">This user is already exist in your chat list</p>
                        <div class="text-center">
                            <a *ngIf="!selectedConnection.chatInitiated" data-bs-dismiss="modal"
                                (click)="markChatMember()" class="p-10"><i class="fas fa-check me-2"></i></a>
                            <a data-bs-dismiss="modal" class="p-10"><i class="fas fa-times close-icon"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<loader [message]="loaderMessage"></loader>
import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { NgxSpinnerService } from "ngx-spinner";
import { User } from "src/app/data/user";
import { Connection } from "src/app/data/Connection";
import { AppDataService } from "src/app/service/appdata.service";
import { Globals } from "src/app/globals";
import { NavigationService } from "src/app/service/navigation.service";
import { AppService } from "src/app/service/app.service";
import { isEmpty, nonEmpty } from "src/app/utils/CommonUtil";

@Component({
  selector: 'app-connection',
  templateUrl: './connection.component.html',
  styleUrls: ['./connection.component.css']
})
export class ConnectionComponent {
  selectedTab: string;
  loggedInUser: User;

  connections: Connection[] = new Array();
  searchTerm: string = '';

  selectedConnection: Connection;

  directoryUrl: string;
  loaderMessage: string = '';

  constructor(private appDataService: AppDataService,
    private router: Router,
    private globals: Globals,
    public navigation: NavigationService,
    private loader: NgxSpinnerService,
    private appService: AppService) {
    this.loggedInUser = appDataService.loggedInUser;
    this.selectedTab = 'connection';
    this.selectedConnection = new Connection();
    this.directoryUrl = environment.directoryUrl;

    this.connections = this.appDataService.chatMembers;
    if (nonEmpty(this.connections)) {
      this.connections = this.connections.filter(u => u.userId != this.loggedInUser.id);
      this.connections = this.connections.map(conn => {
        if (isEmpty(conn.city))
          conn.city = '';
        if (isEmpty(conn.state))
          conn.state = '';
        if (isEmpty(conn.country))
          conn.country = '';
        return conn;
      });
    }
  }

  getFirstLastLetter(user: User) {
    return user.firstName.charAt(0) + user.lastName?.charAt(0);
  }

  isEmpty(str: string) {
    return isEmpty(str);
  }

  setSelected(connection: Connection) {
    this.selectedConnection = connection;
  }

  markChatMember() {
    if (isEmpty(this.selectedConnection)) {
      console.log("Please select a user first.");
      return;
    }
    if (this.selectedConnection.chatInitiated) {
      console.log("Already added for chat");
      return;
    }
    this.loaderMessage = "Adding member to chat...";
    this.loader.show();
    this.appService.addToChatMember(this.selectedConnection)
      .subscribe({
        next: (response) => {
          this.loader.hide();
          if (response.success) {
            for (let conn of this.connections) {
              if (conn.id == this.selectedConnection.id) {
                conn.chatInitiated = true;
                break;
              }
            }
            this.appDataService.chatMembers = this.connections;
          } else {
            console.log("Error occured while adding connection to chat member.");
          }
        },
        error: (error) => { this.handleError(error) }
      });
  }

  goToDirectory() {
    window.open(this.directoryUrl, '_blank');
  }

  handleError(error: any) {
    console.log(error);
    this.loader.hide();
  }
}

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EncryptionUtil } from './utils/EncryptionUtil';
import { throwError } from 'rxjs';
import { AppDataService } from './service/appdata.service';
import { AppConfig } from './data/AppConfig';
import { NavigationEnd, Router } from '@angular/router';

@Injectable()
export class Globals {

    public requestPath: string = 'requestPath';
    currentRoute: string;
    pop: string = "MyS0c1a8l3R3ward";

    constructor(private appDataService: AppDataService,
        private router: Router) {
        this.activateRouterEvent();
    }

    activateRouterEvent() {
        this.router.events.forEach((event: any) => {
            if (event instanceof NavigationEnd) {
                this.currentRoute = event.url;
            }
        });
    }

    isNullOrEmptyOrUndefined = (value: any) => {
        return !value;
    }


    isLoggedIn = () => {
        if (!this.isEmpty(this.appDataService.hiddinToken)) {
            return true;
        }
        return false;
    }

    isEmpty = (value: any) => {
        if (value == null || value == undefined || value.length == 0) {
            return true;
        }
        return false;
    }

    isFutureDate = (date: any): Boolean => {
        if (!(date < new Date(new Date().toDateString()))) {
            return true;
        } else {
            return false;
        }
    }

    getCombineAesKey(): string {
        let signupTimeStr = this.appDataService.hiddinSignUpTime;
        if (this.isEmpty(signupTimeStr)) {
            let userDetail = this.appDataService.loggedInUser;
            if (this.isEmpty(userDetail)) {
                // console.log("Signup time not found");
                throwError("AES Key data not found");
                return '';
            }
            signupTimeStr = userDetail.signupTime as any;
            // console.log("Time: " + signupTimeStr);
        }

        var authTokenObj = this.appDataService.hiddinBogusKey;
        // console.log("authTokenObj: " + authTokenObj);
        if (!this.isEmpty(authTokenObj)) {
            var secretKey = authTokenObj;
            // console.log("secretKey: " + secretKey);

            if (this.isEmpty(secretKey)) {
                //console.log("Secret Key not found");
                throwError("Secret Key not found");
                return '';
            }
            return this.removeSpecialChars((signupTimeStr + secretKey));
        }
        return '';
    }

    removeSpecialChars(text: string) {
        return text.replace(/[^a-zA-Z0-9]/g, '');
    }

    decrypt(encryptedData: string) {
        try {
            if (!environment.isEncryptionEnable) {
                return encryptedData;
            }

            if (this.isEmpty(encryptedData)) {
                return encryptedData;
            }

            var encryptedKey = this.getCombineAesKey() as string;
            //console.log("encryptedKey: " + encryptedKey);

            if (this.isEmpty(encryptedKey)) {
                //console.log("Invalid AES Key");
                throwError("Invalid AES Key");
                return;
            }
            var decryptedData = EncryptionUtil.decrypt(encryptedKey, encryptedData);

            //console.log("Decrypted data: " + decryptedData);

            return JSON.parse(decryptedData);
        } catch (ex) {
            this.unauthorized();
        }
    }

    decryptDefault(encryptedData: string) {

        try {

            if (!environment.isEncryptionEnable) {
                return encryptedData;
            }

            if (this.isEmpty(encryptedData)) {
                return encryptedData;
            }

            var decryptedData = EncryptionUtil.decryptDefault(this.pop, encryptedData);

            // console.log("Decrypted data: " + decryptedData);

            return JSON.parse(decryptedData);
        } catch (ex) {
            this.unauthorized();
        }
    }

    encryptDefault(plainText: string) {

        try {
            if (!environment.isEncryptionEnable) {
                return plainText;
            }

            if (this.isEmpty(plainText)) {
                return plainText;
            }

            var encryptedData = EncryptionUtil.encryptDefault(this.pop, plainText);

            return encryptedData;
        } catch (ex) {
            this.unauthorized();
        }
    }

    public unauthorized = () => {
        let errorMessage: string = "Session is expired.";
        this.appDataService.clearData();
        this.router.navigate(['/login'], { queryParams: { redirectUrl: this.currentRoute, errorMessage: errorMessage } });
    }

}
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'connectionSearchFilter' })
export class ConnectionSearchFilter implements PipeTransform {
    transform(list: any[], filterText: string): any {
        if (!filterText || filterText.trim().length == 0) return list;
        return list ? list.filter(
            item => (item.firstName + ' ' + item.lastName).search(new RegExp(filterText, 'i')) > -1
                || (item.city + ' ' + item.state + ' ' + item.country).search(new RegExp(filterText, 'i')) > -1)
            : [];
    }
}
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { throwError, Observable } from "rxjs";
import { catchError, retry, tap } from "rxjs/operators";
import { AppDataService } from '../service/appdata.service';
import { Router } from '@angular/router';
import { DataService } from '../service/data.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private appData: AppDataService,
    private router: Router,
    private dataService: DataService) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request)

      .pipe(

        tap((event) => {
          if (event instanceof HttpResponse) {
            if (!event.headers.has('Content-Type')) {
              event.headers.set('Content-Type', 'application/json');
            }
            if (event && event.status == 401) {
              // When token is invalid or expired, signin is required in this case
              this.unauthorized(request.urlWithParams, "Unauthorized access");

            }
          }
        }),

        retry(1),

        catchError((error: HttpErrorResponse) => {

          let errorMessage = '';

          if (error.error instanceof ErrorEvent) {

            // client-side error

            errorMessage = 'Error:' + error.error.message;

          } else {
            // server-side error
            errorMessage = 'Error Code:' + error.status + '\nMessage:' + error.message;

            if (error.status === 401) {
              // When token is invalid or expired, signin is required in this case
              this.unauthorized(request.urlWithParams, "Unauthorized access");
            } else if (error.status === 404) {
              // When path is not found
              this.error404();
            } else if (error.status === 0) {
              // alert("Looks like internet issue, please try again!!");
              this.dataService.errorMessage = "Looks like internet is slow or not connected. Please try again later!!";
            }
          }

          // window.alert(errorMessage);
          console.log(errorMessage);

          return throwError(errorMessage);

        })

      )

  }

  public unauthorized = (redirectUrl: string, errorMessage: string) => {
    this.appData.clearData();
    this.router.navigate(['/login'], { queryParams: { redirectUrl: redirectUrl, errorMessage: errorMessage } });
  }

  public error404 = () => {
    this.router.navigate(['/error', '404']);
  }

  public error500 = () => {
    this.router.navigate(['/error', '500']);
  }
}

<div class="sidebar-menu">
    <div class="logo-col">
      <div class="bottom-menus">
        <ul>
          <!-- <li><a href="#" data-bs-toggle="modal" data-bs-target="#add-new-group"><span class="material-icons group-add-btn">group_add</span><span>Add Groups</span></a></li><li><a href="#" class="add-contacts-btn" data-bs-toggle="modal" data-bs-target="#add-contact"><i class="fas fa-plus"></i><span>Add Contacts</span></a></li> -->
        
          <li>
            <a *ngIf="loggedInUser.img" class="chat-profile-icon" data-bs-toggle="dropdown">
              <img [src]="loggedInUser.img" alt="">
            </a>
            <div *ngIf="!loggedInUser.img" class="avatar " data-bs-toggle="dropdown">
              <div class="letter-avatar">
                {{getFirstLastLetter()}}
              </div>
            </div>
         
            <div class="dropdown-menu dropdown-menu-end">
              <!-- <a href="#" class="dropdown-item dream_profile_menu">Edit Profile <span
                                  class="edit-profile-icon"><i class="fas fa-edit"></i></span></a><a href="#" class="dropdown-item">Profile <span class="profile-icon-col"><i
                                      class="fas fa-id-card-alt"></i></span></a><a href="settings.html" class="dropdown-item">Settings <span
                                  class="material-icons">settings</span></a><a href="archived.html" class="dropdown-item">Archived <span
                                  class="material-icons">flag</span></a> -->
              <a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#user-logout">Logout <span class="material-icons">power_settings_new</span>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="menus-col">
      <div class="chat-menus">
        <ul>
          <li>
            <a [routerLink]="['/chat/0']" (click)="changeTab('chat', '/chat/0')" class="chat-unread" [class.blue]="selectedTab === 'chat'">
              <span class="material-icons">message</span>
              <span>Chats</span>
            </a>
          </li>
          <!-- <li><a href="group.html" class="chat-unread pink"><span class="material-icons">group</span><span>Groups</span></a></li>-->
          <li>
            <a [routerLink]="['/connection']" (click)="changeTab('connection', '/connection')" class="chat-unread" [class.blue]="selectedTab === 'connection'">
              <span class="material-icons">group</span>
              <span>Connection</span>
            </a>
          </li>
          <!--  <li><a href="audio-call.html" class="chat-unread"><span class="material-icons">call</span><span>Calls</span></a></li><li><a href="settings.html" class="chat-unread"><span class="material-icons">settings</span><span>Settings</span></a></li> -->
        </ul>
      </div>
      <div class="bottom-menus">
        <ul>
          <!-- <li><a href="#" data-bs-toggle="modal" data-bs-target="#add-new-group"><span class="material-icons group-add-btn">group_add</span><span>Add Groups</span></a></li><li><a href="#" class="add-contacts-btn" data-bs-toggle="modal" data-bs-target="#add-contact"><i class="fas fa-plus"></i><span>Add Contacts</span></a></li> -->
          <li>
            <a id="dark-mode-toggle" class="dark-mode-toggle " (click)="changeDarkMode()">
              <i class="far fa-moon"></i>
            </a>
          </li>
          <li>
            <a id="dark-mode-toggle" class="dark-mode-toggle " (click)="logout()">
              <i class="fa fa-sign-out-alt"></i>
            </a>
          </li>
          <li>
            <img src="assets/images/logo-text-wh.png" alt="" class="sideLogoSize">
         
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="modal fade" id="user-logout">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"> Logout </h5>
          <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
            <span class="material-icons">close</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="success-icon delete-tab text-center">
            <p>Are you sure want to logout?</p>
            <div class="text-center">
              <a data-bs-dismiss="modal" (click)="logout()" class="p-10">
                <i class="fas fa-check me-2"></i>
              </a>
              <a data-bs-dismiss="modal" class="p-10">
                <i class="fas fa-times close-icon"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
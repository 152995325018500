export class User {
  id: number;
  userId?: number;
  firstName: string;
  lastName?: string;
  userName?: string;
  online?: boolean;
  lastSeenTime?: string;
  city?: string;
  state?: string;
  country?: string;
  img?: string;
  signupTime?: string;
  timeZone: string;
}
import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import { User } from '../data/user';
import { AppDataService } from '../service/appdata.service';

@Pipe({
  name: 'lastSeenTime'
})
export class LastSeenTimePipe implements PipeTransform {
  weekday = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
  loggedInUser: User;

  constructor(private appDataService: AppDataService) {
    this.loggedInUser = appDataService.loggedInUser;
  }

  transform(value: any) {
    let valueMoment = moment(value).tz(this.loggedInUser.timeZone);
    let now = moment(moment.now()).tz(this.loggedInUser.timeZone);
    let diff = now.diff(valueMoment, "days");
    // console.log("Diff Moment: ", diff);

    if (diff == 0) {
      if (now.day == valueMoment.day)
        return valueMoment.format("hh:mm A");
      else
        return "yesterday";
    }
    else if (diff == 1)
      return "yesterday";
    else if (diff < 6) {
      return this.weekday[valueMoment.day()];
    } else {
      return (new DatePipe("en-US")).transform(value, 'dd/MM/yyyy');
    }
  }

  formatTime(date: any) {
    let hours = date.getHours();
    let minutes = date.getMinutes();

    // Check whether AM or PM
    let newformat = hours >= 12 ? 'PM' : 'AM';

    // Find current hour in AM-PM Format
    hours = hours % 12;

    // To display "0" as "12"
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;

    return (hours + ':' + minutes + ' ' + newformat);
  }
}

import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Globals } from '../globals';
import { environment } from 'src/environments/environment';
import { throwError } from 'rxjs';
import { EncryptionUtil } from '../utils/EncryptionUtil';
import { AppDataService } from './appdata.service';
import { XHRHandler } from './xhrhandler.service';
import { Router } from '@angular/router';
import { isEmpty } from '../utils/CommonUtil';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private xhrhandler: XHRHandler,
    private globals: Globals,
    private appData: AppDataService,
    private httpClient: HttpClient,
    private router: Router) {
  }

  public getToken = () => {
    var tokenString = this.appData.hiddinToken;
    if (this.globals.isEmpty(tokenString)) {
      return null;
    }
    return tokenString;
  }

  public isLoggedIn = () => {
    if (this.globals.isEmpty(this.getToken())) {
      return false;
    }
    return true;
  }

  public authorized = () => {
    if (this.getToken()) {
      return true;
    }
    return false;
  }

  public unauthorized = (redirectUrl: string) => {
    this.logout();
    this.router.navigate(['/login?redirectUrl=' + redirectUrl]);
  }

  public error404 = () => {
    this.router.navigate(['/error', '404']);
  }

  public error500 = () => {
    this.router.navigate(['/error', '500']);
  }

  public logout = () => {
    this.appData.clearData();
  }

  public getNoSecure = (path: string) => {
    return this.xhrhandler.doGet(path);
  }

  public get = (path: string) => {
    if (!this.authorized()) {
      return false;
    }
    return this.xhrhandler.doGetWithAutHeader(path);
  }

  public postNoSecure = (path: string, data: any) => {
    return this.xhrhandler.doPost(path, data);
  }

  public post = (path: string, data: any) => {
    if (!this.authorized()) {
      return false;
    }
    return this.xhrhandler.doPostWithAuthHeader(path, data);
  }

  public postEncrypt = (path: string, data: any) => {
    if (!environment.isEncryptionEnable) {
      return this.post(path, data);
    }

    if (!this.authorized()) {
      return null;
    }

    var dataStr = JSON.stringify(data);
    //   console.log("Data to send: " + dataStr);

    var encryptedKey = this.globals.getCombineAesKey() as string;
    //   console.log("encryptedKey: " + encryptedKey);

    if (this.globals.isEmpty(encryptedKey)) {
      throwError("Invalid AES Key");
    }

    var encryptedData = EncryptionUtil.encrypt(encryptedKey, dataStr);
    // Append encrypted parameter; encrypted=true
    if (path.indexOf("?") >= 0) {
      path = path + "&encrypted=true&defaultEncrypted=false";
    } else {
      path = path + "?encrypted=true&defaultEncrypted=false";
    }

    return this.post(path, encryptedData);
  }

  public postEncryptNoSecure = (path: string, data: any) => {
    if (!environment.isEncryptionEnable) {
      return this.postNoSecure(path, data);
    }

    if (!this.authorized()) {
      return null;
    }

    var dataStr = JSON.stringify(data);

    var encryptedKey = this.globals.getCombineAesKey();

    if (this.globals.isEmpty(encryptedKey)) {
      throwError("Invalid AES Key");
    }

    var encryptedData = EncryptionUtil.encrypt(dataStr, encryptedKey);
    return this.postNoSecure(path, encryptedData);
  }

  public postDefaultEncrypt = (path: string, data: any) => {

    if (!environment.isEncryptionEnable) {
      return this.postNoSecure(path, data);
    }

    var dataStr = JSON.stringify(data);
    // console.log("Data to send: " + dataStr);

    var encryptedData;
    if (!this.globals.isEmpty(data)) {
      encryptedData = EncryptionUtil.encryptDefault(this.globals.pop, dataStr);
      // console.log("encryptedData: " + encryptedData);
    };

    // Append encrypted parameter; encrypted=true
    if (path.indexOf("?") >= 0) {
      path = path + "&encrypted=true&defaultEncrypted=true";
    } else {
      path = path + "?encrypted=true&defaultEncrypted=true";
    }

    return this.postNoSecure(path, encryptedData);
  }

  public postDefaultEncryptNoSecure = (path: string, data: any) => {

    if (!environment.isEncryptionEnable) {
      return this.postNoSecure(path, data);
    }

    var dataStr = JSON.stringify(data);
    //   console.log("Data to send: " + dataStr);

    var encryptedData;
    if (!this.globals.isEmpty(data)) {
      encryptedData = EncryptionUtil.encryptDefault(this.globals.pop, dataStr);
      // console.log("encryptedData: " + encryptedData);
    };

    // Append encrypted parameter; encrypted=true
    if (path.indexOf("?") >= 0) {
      path = path + "&encrypted=true&defaultEncrypted=true";
    } else {
      path = path + "?encrypted=true&defaultEncrypted=true";
    }
    return this.postNoSecure(path, encryptedData);
  }

  public postEcryptWithToken = (path: string, data: any, token: string) => {
    if (this.globals.isEmpty(token)) {
      this.unauthorized('');
    }

    if (!environment.isEncryptionEnable) {
      return this.httpClient.post(environment.SERVER_BASE_URL + path, data, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + JSON.parse(token)
        })
      });
    }

    var dataStr = JSON.stringify(data);
    // console.log("Data to send: " + dataStr);

    var encryptedData;
    if (!this.globals.isEmpty(data)) {
      encryptedData = EncryptionUtil.encryptDefault(this.globals.pop, dataStr);
      // console.log("encryptedData: " + encryptedData);
    }

    // Append encrypted parameter; encrypted=true
    if (path.indexOf("?") >= 0) {
      path = path + "&encrypted=true&defaultEncrypted=true";
    } else {
      path = path + "?encrypted=true&defaultEncrypted=true";
    }

    return this.httpClient.post(environment.SERVER_BASE_URL + path, encryptedData, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JSON.parse(token)
      })
    });
  }

  uploadFileNoSecure = (path: string, file: any) => {
    var fd = new FormData();
    fd.append("file", file);
    return this.xhrhandler.doPost(path, fd);
  }

  uploadFile = (path: string, file: any) => {
    if (!this.authorized()) {
      return null;
    }
    var fd = new FormData();
    fd.append("file", file);
    return this.xhrhandler.doPostWithAuthHeader(path, fd);
  }

}


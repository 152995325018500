import * as moment from 'moment-timezone';

/**
 * This utility class is designed for date and time conversions based on timezones
 */
export class TimeZoneUtils {

    public static DATE_FORMAT = "MM/DD/YYYY";
    public static TIME_FORMAT = "H:mm A";
    public static DEFAULT_DATE = moment().format(TimeZoneUtils.DATE_FORMAT);
    public static SPACE_1 = " ";
    public static DATE_TIME_FORMAT = TimeZoneUtils.DATE_FORMAT + TimeZoneUtils.SPACE_1 + TimeZoneUtils.TIME_FORMAT;

    /**
     * This method only converts Time to UTC Time
     * Ex: 09:00 AM, IST => 03:30 AM, UTC
     * 
     * @param time current time
     * @param userTimeZone timezone of current time
     * @returns UTC time
     */
    public static convertOnlyTimeToUTC(inputTime: any, userTimeZone: any) {
        return moment
            .tz(TimeZoneUtils.DEFAULT_DATE + TimeZoneUtils.SPACE_1 + inputTime, TimeZoneUtils.DATE_TIME_FORMAT, userTimeZone)
            .utc().format(TimeZoneUtils.TIME_FORMAT);
    }

    /**
     * This method only converts UTC Time to User Time
     * Ex: 03:30 AM => 09:00 AM, IST
     * 
     * @param utcTime UTC time
     * @param userTimeZone user's timezone
     * @returns time in user's timezone
     */
    public static convertUTCToCurrentTime(utcTime: any, userTimeZone: any) {
        return moment
            .utc(TimeZoneUtils.DEFAULT_DATE + TimeZoneUtils.SPACE_1 + utcTime, TimeZoneUtils.DATE_TIME_FORMAT)
            .tz(userTimeZone)
            .format(TimeZoneUtils.TIME_FORMAT);
    }

    public static convertUTCToCurrentTimeMoment(utcTime: any, userTimeZone: any) {
        return moment
            .utc(TimeZoneUtils.DEFAULT_DATE + TimeZoneUtils.SPACE_1 + utcTime, TimeZoneUtils.DATE_TIME_FORMAT)
            .tz(userTimeZone);
    }

    /**
     * This method converts Date Time to UTC Date Time
     * Ex: 21/05/2023 09:00 AM, IST => 21/05/2023 03:30 AM, UTC
     * 
     * @param inputDateTime current date time
     * @param userTimeZone timezone of current time
     * @returns UTC time
     */
    public static convertDateTimeToUTC(inputDateTime: any, userTimeZone: any) {
        return moment
            .tz(inputDateTime, TimeZoneUtils.DATE_TIME_FORMAT, userTimeZone)
            .utc()
            .format(TimeZoneUtils.DATE_TIME_FORMAT);
    }

    /**
     * This method only converts UTC Date Time to User Date Time
     * Ex: 21/05/2023 03:30 AM => 21/05/2023 09:00 AM, IST
     * 
     * @param inputDateTime UTC date time
     * @param userTimeZone user's timezone
     * @returns time in user's timezone
     */
    public static convertUTCToDateTime(inputDateTime: any, userTimeZone: any) {
        return moment
            .utc(inputDateTime, TimeZoneUtils.DATE_TIME_FORMAT)
            .tz(userTimeZone)
            .format(TimeZoneUtils.DATE_TIME_FORMAT);
    }
}
import { HttpHeaders } from '@angular/common/http';

export class CommonHeader {

  static getCommonHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
  }

  static getAuthHeaders = (token: string) => ({
    headers: new HttpHeaders({
      Authorization: 'Bearer ' + token,
      Accept: 'application/json'
    }),
    'Content-Type': 'application/json; charset=utf-8'
  });

  public getFileHeaders = (token: string) => ({
    headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token
    })
  });
}
export const isEmpty = (value: any): any => {
    return value == null || value == undefined || Object.keys(value).length === 0;
};

export const nonEmpty = (value: any) => {
    return value !== null && value !== undefined && Object.keys(value).length !== 0;
}

export const delay = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const messageKey = (senderId: Number, receiverId: Number) => {
    return senderId + '' + receiverId;
}

export const pagination = (page: Number, size: Number) => {
    return '?page=' + page + '&size=' + size;
}

export const getFullName = (user: any) => {
    if (nonEmpty(user)) {
        let fullName = user.firstName;
        if (nonEmpty(user.lastName)) {
            fullName += (" " + user.lastName);
        }
        return fullName;
    }
    return '';
}
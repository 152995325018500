import { Component, OnInit, ViewChild, ElementRef, Input, OnDestroy, EventEmitter } from '@angular/core';
import Janus from 'src/app/utils/janus';
import JanusHandle from 'src/app/utils/janus_handle';
import * as moment from 'moment-timezone';
import { WebsocketEvents } from 'src/app/constants/WebsocketEvents';
import { Connection } from 'src/app/data/Connection';
import { ModalService } from 'src/app/service/modal.service';
import { Message } from 'src/app/data/message';
import { User } from 'src/app/data/user';
import { ConnectionType } from 'src/app/constants/ConnectionType';
import { getFullName, nonEmpty } from 'src/app/utils/CommonUtil';
import { Subscription } from 'rxjs';
import { AppDataService } from 'src/app/service/appdata.service';
import { environment } from 'src/environments/environment';
import { WebSocketService } from 'src/app/service/websocket.service';
import VideoRoomCreateOptions from 'src/app/utils/janus_videoroom';
import { AppConfig } from 'src/app/data/AppConfig';
import { CommunicationService } from 'src/app/service/communication.service';
import { Globals } from 'src/app/globals';
import { EncryptionUtil } from 'src/app/utils/EncryptionUtil';

@Component({
  selector: 'app-video-play',
  templateUrl: './video-play.component.html',
  styleUrls: ['./video-play.component.scss'],
})
export class VideoPlayComponent implements OnInit, OnDestroy {
  loggedInUser: User;
  // Selected user in chat members
  @Input() selectedUser: Connection;
  @Input("appConfig") appConfig: AppConfig;
  @Input() eventEmitter: EventEmitter<Message>;

  @ViewChild('localVideo') localVideo: ElementRef
  @ViewChild('remoteAudio') remoteAudio: ElementRef
  @ViewChild('remoteVideo') remoteVideo: ElementRef

  //Speaker On/Off Button
  @ViewChild('speakoffButton') speakoffButton: ElementRef
  @ViewChild('speakButton') speakButton: ElementRef

  audioElement: HTMLAudioElement;


  private session: Janus;
  private localHandle: JanusHandle | null;
  private server: string;
  private iceServers: any;
  private icePolicy: string;
  private token: string;
  private apiSecret: string;

  private debug: string = environment.VIDEO_DEBUG_LEVEL;
  private useMSId: boolean = false;

  room: number = 0;
  roomCreated: boolean = false;
  roomExists: boolean = false;
  roomJoined: boolean = false;
  communicationKeyLoaded: boolean = false;

  private remoteHandle: JanusHandle | null;
  private feeds: any = {};
  private subStreams: any = {};
  private slots: any = {};
  private mids: any = {};
  private subscriptions: any = {};
  private bitrateTimer: any = [];
  private simulcastStarted: any = {};
  private svcStarted: any = {};

  opaqueId: string = "VTeam-" + Janus.randomString(12);

  //imported
  private subscription: Subscription;
  callerTune: string = 'assets/tunes/tune5.mp3';

  callerId: number;
  callerName: string;
  errorMessageVideo: string = '';
  userIdVsCount = new Map();

  callButtonEnabled: boolean = false;
  unmuteButtonDisplay: boolean = true;
  muteButtonDisplay: boolean = false;
  localVideoDisable: boolean = false;
  flipButtonEnabled: boolean = true;
  speakerOnButtonDisplay: boolean = true;
  speakerOffButtonDisplay: boolean = false;
  hangButtonEnabled: boolean = true;
  showAcceptRejectButton: boolean = false;
  xhr: XMLHttpRequest;

  unmuteAudioButtonDisplay: boolean = true;
  muteAudioButtonDisplay: boolean = false;
  audioSpeakerOnButtonDisplay: boolean = true;
  audioSpeakerOffButtonDisplay: boolean = false;
  timerInterval:any;
  timerHours: number = 0;
  timerMinutes: number = 0;
  timerSeconds: number = 0;

  videoDevices: any = [];
  audioOutDevices: any = [];
  audioInDevices: any = [];
  deviceIndex = 0;
  defaultAudioDeviceId: string = '';
  defaultVideoDeviceId: string = '';

  isChannelReady: boolean = false;
  isStreamer: boolean = false;
  isStarted: boolean = false;
  inWaitingStream: any;
  turnReady: boolean;

  status: string;

  secret: string = "";
  roomAdminKey: string;
  pin: string = "";
  encryptionKey: string = "V3ry$3cur3d";
  currentKeyIdentifier: number = 1;
  useCryptoOffset: boolean = true;


  doSimulcast: boolean = false;
  doSvc: string;
  // acodec: string = "opus";
  // vcodec: string = "vp9";
  acodec: string;
  vcodec: string;
  myPVTId: string;
  myId: string;
  feedStreams: any = {};
  localTracks: any = {};
  localVideos: number = 0;
  remoteTracks: any = {};
  randomNumber = "00000";

  constructor(
    private modalService: ModalService,
    private appDataService: AppDataService,
    private webSocketService: WebSocketService,
    private communicationService: CommunicationService,
    private globals: Globals
  ) {
    // Loggedin user from cache
    this.loggedInUser = appDataService.loggedInUser;
  }

  generateRandomNumer() {
    this.randomNumber = "" + (Math.floor(Math.random() * 90000) + 10000);
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    window.onbeforeunload = () => this.ngOnDestroy();
    this.initializeAppConfig();
    this.initializePageElements();
    this.subscribeToParentEmitter();
    this.initializeJanusVideoServer();
  }

  subscribeToParentEmitter(): void {
    this.subscription = this.eventEmitter.subscribe((msg: Message) => {
      if (msg.type == ConnectionType.VIDEO) {
        this.handleVideoMessages(msg);
      } else if (msg.type == ConnectionType.INITIATE_AUDIO) {
        this.secret = this.appDataService.commKey.aesKey.substring(0, 10);
        this.pin = this.secret;
        console.log("Pin generated successfully");
        this.startAudioCall();
      } else if (msg.type == ConnectionType.INITIATE_VIDEO) {
        this.secret = this.appDataService.commKey.aesKey.substring(0, 10);
        this.pin = this.secret;
        console.log("Pin generated successfully");
        this.startVideoCall();
      } else {
        if (environment.loggingEnabled) console.log("Invalid type in video modal component");
      }
    });
  }

  ngOnDestroy() {
    var terminationMessage = this.createTerminationMessage();
    this.webSocketService.sendMessage(terminationMessage);
    this.terminateVideoSession();
    this.shutdown();
    this.resetTimer();
  }

  initializeAppConfig() {
    this.server = this.appConfig.VIDEO_SERVER_URL;
    this.iceServers = [
      {
        urls: this.appConfig.TURN_SERVER_URL,
        username: this.appConfig.TURN_USER_ID,
        credential: this.appConfig.TURN_USER_PASSWORD
      },
      {
        urls: this.appConfig.STUN_SERVER_URL,
        username: this.appConfig.TURN_USER_ID,
        credential: this.appConfig.TURN_USER_PASSWORD
      },
    ];
    this.icePolicy = this.appConfig.ICE_POLICY;
    this.token = this.appConfig.VIDEO_API_CRED;
    this.apiSecret = this.appConfig.VIDEO_API_CRED;
    this.roomAdminKey = this.appConfig.VIDEO_API_CRED;
  }

  terminateVideoSession() {
    if (environment.loggingEnabled) console.log("Terminating Vedio Session ", this.isJanusConnected());
    if (this.isJanusConnected()) {
      if (this.isStreamer) {
        this.destroy();
      } else {
        this.leave();
      }
      this.disconnect();
    }
    this.removeRoom();
  }

  // Audio
  startAudioCall() {
    this.callerName = getFullName(this.selectedUser) as string;
    this.openAudioModal();
    this.startCallTimer();
  }

  closeAudioCall() {
    this.closeAudioModal();
    this.resetTimer();
  }

  startCallTimer() {
    this.timerInterval = setInterval(() => {
      this.timerSeconds++;
      if (this.timerSeconds == 59) {
        this.timerMinutes++;
        this.timerSeconds = 0;
        if (this.timerMinutes == 59) {
          this.timerHours++;
          this.timerMinutes = 0;
        }
      }
    }, 1000);
  }

  resetTimer() {
    if(this.timerInterval)
      clearInterval(this.timerInterval);
  }

  getFirstLastLetterFromFullName(fullName: string) {
    if (nonEmpty(fullName)) {
      let splitArray = fullName.split(" ");
      if (splitArray.length == 1) {
        return splitArray[0].charAt(0);
      } else if (splitArray.length > 1) {
        return splitArray[0].charAt(0) + splitArray[1].charAt(0);
      }
    }
    return '';
  }

  muteAudio() {
    this.unmuteAudioButtonDisplay = false;
    this.muteAudioButtonDisplay = true;
  }

  unmuteAudio() {
    this.unmuteAudioButtonDisplay = true;
    this.muteAudioButtonDisplay = false;
  }

  audioSpeakerOn() {
    this.audioSpeakerOffButtonDisplay = false;
    this.audioSpeakerOnButtonDisplay = true;
  }

  audioSpeakerOff() {
    this.audioSpeakerOffButtonDisplay = true;
    this.audioSpeakerOnButtonDisplay = false;
  }

  // // Video
  startVideoCall() {
    if (environment.loggingEnabled) console.log("Initiating the call");
    this.errorMessageVideo = '';
    this.isStreamer = true;
    this.callerName = getFullName(this.selectedUser) as string;
    this.startCallTimer();
    this.generateRandomNumer();
    this.setRoomId();
    // Ring the bell
    this.startRinger();
    this.startVideoElements();
    this.connectJanusVideoSession();
    this.initializeTransformMethods();
    // Send initiate message
    let message = this.createCallInitiationMessage();
    this.webSocketService.sendMessage(message);
    // open video call popup
    this.openVideoModal();
  }

  createCallInitiationMessage() {
    let message: Message = {
      type: ConnectionType.VIDEO,
      subType: WebsocketEvents.INITIATE,
      from: this.loggedInUser.id,
      to: this.selectedUser.userId,
      fromUserName: getFullName(this.loggedInUser),
      toUserName: getFullName(this.selectedUser),
      message: null,
      room: parseInt("" + this.randomNumber),
      communicationId: this.appDataService.commKey.communicationId,
      creationTime: moment.utc().format()
    }
    return message;
  }

  createTerminationMessage() {
    let message: Message = {
      type: ConnectionType.VIDEO,
      subType: WebsocketEvents.TERMINATE,
      from: this.loggedInUser?.id,
      to: this.selectedUser?.userId,
      fromUserName: getFullName(this.loggedInUser),
      toUserName: getFullName(this.selectedUser),
      message: null,
      room: parseInt("" + this.randomNumber),
      communicationId: '',
      creationTime: moment.utc().format()
    }
    return message;
  }

  handlePublishEvent(message: Message) {
    //caller started publishing, publish yourself now
    //this.janus.publish();
  }

  acceptAndJoinVideoCall(message: Message) {
    this.isStreamer = false;
    //this.generateRoomId();
    // Stop the bell
    this.stopRinger();
    // Call started
    this.startVideoElements();
    //this.janus.publish();
    var message = this.createPublishMessage();
    this.webSocketService.sendMessage(message);
  }

  createPublishMessage() {
    let message: Message = {
      type: ConnectionType.VIDEO,
      subType: WebsocketEvents.PUBLISH,
      from: this.loggedInUser?.id,
      to: this.selectedUser?.userId,
      fromUserName: getFullName(this.loggedInUser),
      toUserName: getFullName(this.selectedUser),
      message: null,
      room: parseInt("" + this.randomNumber),
      communicationId: this.appDataService.commKey.communicationId,
      creationTime: moment.utc().format()
    }
    return message;
  }

  handleCloseEvent(message: Message) {
    this.isStreamer = false;
    this.isStarted = false;
    // Stop the bell
    this.stopRinger();
    this.resetRoom();
    // Call Ended
    this.closeAcceptRejectModal();
    this.handleRemoteGoodBye();
  }


  setRoomId() {
    if (this.isStreamer) {
      this.room = parseInt(this.loggedInUser.id + "00003" + this.selectedUser.userId);
    } else {
      this.room = parseInt(this.selectedUser.userId + "00003" + this.loggedInUser.id);
    }
  }

  resetRoom() {
    this.room = 0;
  }

  acceptVideoCall() {
    if (environment.loggingEnabled) console.log("Call accepting...");
    let message = this.createAcceptMessage();
    this.webSocketService.sendMessage(message);
    this.stopRinger();
    this.closeAcceptRejectModal();
    this.initializeTransformMethods();
    this.openVideoModal();
    this.acceptCall();
  }

  createAcceptMessage() {
    let message: Message = {
      type: ConnectionType.VIDEO,
      subType: WebsocketEvents.ACCEPTED,
      from: this.loggedInUser.id,
      to: this.callerId,
      fromUserName: getFullName(this.loggedInUser),
      toUserName: this.callerName,
      message: null,
      room: parseInt("" + this.randomNumber),
      communicationId: this.appDataService.commKey.communicationId,
      creationTime: moment.utc().format()
    }
    return message;
  }

  rejectVideoCall() {
    let message = this.createRejectMessage();
    this.webSocketService.sendMessage(message);
    this.stopRinger();
    this.resetRoom();
    this.resetCallerDetail();
    this.closeAcceptRejectModal();
  }

  createRejectMessage() {
    let message: Message = {
      type: ConnectionType.VIDEO,
      subType: WebsocketEvents.REJECTED,
      from: this.loggedInUser.id,
      to: this.callerId,
      fromUserName: getFullName(this.loggedInUser),
      toUserName: getFullName(this.selectedUser),
      message: null,
      room: parseInt("" + this.randomNumber),
      communicationId: this.appDataService.commKey.communicationId,
      creationTime: moment.utc().format()
    }
    return message;
  }

  resetCallerDetail() {
    this.callerId = -1;
    this.callerName = '';
  }
  // Set up audio and video regardless of what devices are present.
  // sdConstraints = {
  //   offerToReceiveAudio: true,
  //   offerToReceiveVideo: true
  // };

  // /////////////////////////////////////////////

  initializePageElements() {
    this.setInitialVideoAndAudio();
    this.checkPermissionsAndSetDevices();
  }

  // ////////////////////////////////////////////////
  connectJanusVideoSession() {
    if (environment.loggingEnabled) console.log("Connecting to Janus");

    this.isChannelReady = true;
    if (environment.loggingEnabled) console.log("Room to be created/joined", this.room);

    if (this.isStreamer) {
      this.createRoom({});
      this.waitForRoomCreationThenJoin();
    } else {
      if (environment.loggingEnabled) console.log("Joining Room", this.room);
      this.waitForCommunicationKeyIfNotLoaded();
      this.joinRoom({});
    }
    return true;
  }

  waitForRoomCreationThenJoin() {
    setTimeout(() => {
      if (this.roomCreated == false) {
        this.waitForRoomCreationThenJoin();
      } else if (this.roomJoined == false) {
        if (environment.loggingEnabled) console.log("Joining Room", this.room);
        this.joinAndConfigure({});
        this.roomJoined = true;
      }
    }, 2000);
  }

  waitForCommunicationKeyIfNotLoaded() {
    setTimeout(() => {
      if (this.communicationKeyLoaded == false) {
        this.waitForCommunicationKeyIfNotLoaded();
      }
    }, 2000);
  }

  handleVideoMessages(message: Message) {
    if (environment.loggingEnabled) console.log("Event Message: " + message.type, message.subType);

    if (message.subType == WebsocketEvents.INITIATE) {
      this.handleInitiateEvent(message);
    } else if (message.subType == WebsocketEvents.ACCEPTED) {
      // only current room accepted
      if (this.randomNumber != "" + message.room) {
        return;
      }
      this.acceptAndJoinVideoCall(message);
    } else if (message.subType == WebsocketEvents.TERMINATE) {
      // only current room to be terminated
      if (this.randomNumber != "" + message.room) {
        return;
      }
      this.handleCloseEvent(message);
      this.errorMessageVideo = "User closed the call";
    } else if (message.subType == WebsocketEvents.PUBLISH) {
      // only current room to be terminated
      if (this.randomNumber != "" + message.room) {
        return;
      }
      this.handlePublishEvent(message);
    } else if (message.subType == WebsocketEvents.REJECTED) {
      // only current room accepted
      if (this.randomNumber != "" + message.room) {
        return;
      }
      this.handleCloseEvent(message);
      this.errorMessageVideo = "User declined the call";
    } else if (message.subType == WebsocketEvents.OFFLINE) {
      // only current room accepted
      if (this.randomNumber != "" + message.room) {
        return;
      }
      this.handleCloseEvent(message);
      this.errorMessageVideo = "User is offline";
    }
  }

  handleInitiateEvent(message: Message) {
    // Set caller information
    this.randomNumber = "" + message.room;
    this.setRoomId();
    this.callerId = message.from;
    this.callerName = message.fromUserName as string;

    this.getCommunicationKey(message.communicationId);

    // Ring the bell
    this.startRinger();
    // Show Accept/Reject Modal
    if (!this.isStreamer) {
      this.openAcceptRejectModal();
    }
  }

  async getCommunicationKey(communicationId: string) {
    await this.communicationService.fetchAesKeysForCommIds(communicationId)
      .subscribe({
        next: (response) => {
          if (response && response.success) {
            let aesData = this.globals.decrypt(response.data);
            // console.log("commIdVsAesKeys: ", aesData);
            Object.keys(aesData.commIdVsKey).forEach(commId => {
              this.secret = aesData.commIdVsKey[commId].substring(0, 10);
              this.pin = this.secret;
              console.log("PIN generated successfully.");
              this.communicationKeyLoaded = true;
            });
          }
        },
        error: (error) => { console.log("Error occured while fetching communication key: ", error) }
      });
  }

  videoWidth = 1280;
  videoHeight = 720;
  videoConstraints = {
    audio: {
      echoCancellation: true
    },
    video: {
      width: { min: 320, ideal: 1280, max: 1920, exact: this.videoWidth },
      height: { min: 240, ideal: 720, max: 1080, exact: this.videoHeight },
      deviceId: this.defaultVideoDeviceId
    }
  };

  simplevideoConstraints = {
    audio: true,
    video: {
      width: { exact: this.videoWidth },
      height: { exact: this.videoHeight }
    }
  };

  handleSubscriberLocalTrack(track: MediaStreamTrack, on: boolean) {
    // The subscriber stream is recvonly, we don't expect anything here
  }

  handlePublisherLocalTrack(track: MediaStreamTrack, on: boolean) {
    if (environment.loggingEnabled) console.log(" ::: Got a local track event :::");
    if (environment.loggingEnabled) console.log("Local track " + (on ? "added" : "removed") + ":", track);
    // We use the track ID as name of the element, but it may contain invalid characters
    let trackId = track.id.replace(/[{}]/g, "");

    if (!on) {
      // Track removed, get rid of the stream and the rendering
      let stream = this.localTracks[trackId];
      if (stream) {
        try {
          let tracks = stream.getTracks();
          for (let i in tracks) {
            let mst = tracks[i];
            if (mst)
              mst.stop();
          }
        } catch (e) { }
      }
      if (track.kind === "video") {
        this.localVideos--;
        if (this.localVideos === 0) {
          //TO BE HANDLED
        }
      }
      delete this.localTracks[trackId];
      return;
    }
    // If we're here, a new track was added
    let stream = this.localTracks[trackId];
    if (stream) {
      // We've been here already
      return;
    }
    if (track.kind === "audio") {
      //No Audio in local video
    } else {
      // New video track: create a stream out of it
      this.localVideos++;
      let stream = new MediaStream([track]);
      this.localTracks[trackId] = stream;
      Janus.attachMediaStream(this.localVideo.nativeElement, stream);
    }
  }

  handlePublisherRemoteTrack(track: any, mid: any, on: any, metadata: any) {
    // The publisher stream is sendonly, we don't expect anything here
    if (environment.loggingEnabled) console.log(
      "Publisher Remote track (mid=" + mid + ") " +
      (on ? "added" : "removed") +
      (metadata ? " (" + metadata.reason + ") " : "") + ":", track
    );
  }

  handleSubscriberRemoteTrack(track: any, mid: any, on: any, metadata: any) {
    if (environment.loggingEnabled) console.log(
      "Subscriber Remote track (mid=" + mid + ") " +
      (on ? "added" : "removed") +
      (metadata ? " (" + metadata.reason + ") " : "") + ":", track
    );
    if (!on) {
      if (environment.loggingEnabled) console.log("Remote track removed");
      return;
    }
    // If we're here, a new track was added
    if (environment.loggingEnabled) console.log("New remote track added");
    if (track.kind === "audio") {
      // New audio track: create a stream out of it, and use a hidden <audio> element
      let stream = new MediaStream([track]);
      this.remoteTracks[mid] = stream;
      if (environment.loggingEnabled) console.log("Created remote audio stream:", stream);
      Janus.attachMediaStream(this.remoteAudio.nativeElement, stream);
    } else {
      let stream = new MediaStream([track]);
      this.remoteTracks[mid] = stream;
      if (environment.loggingEnabled) console.log("Created remote video stream:", stream);
      Janus.attachMediaStream(this.remoteVideo.nativeElement, stream);
    }
  }
  // ////////////////////////////////////////////////////

  checkPermissionsAndSetDevices() {
    if ((navigator as any).permissions && (navigator as any).permissions.query({ name: 'camera' })) {
      (navigator as any).permissions.query({ name: 'camera' }, { name: 'microphone' }).then((permission: { state: string; }) => {
        if (permission.state == 'granted') {
          this.setAudioVideoInputOptionValues();
        } else if (permission.state == 'prompt') {
          this.requestAudioVideoPermissions();
        } else {
          this.permissionDenied();
        }
      });
    } else {
      this.requestAudioVideoPermissions();
    }
  }

  requestAudioVideoPermissions() {
    navigator.mediaDevices.getUserMedia(this.videoConstraints).then((stream: MediaStream) => {
      this.permissionGranted(stream);
    }).then((error: any) => {
      this.permissionDenied;
    });
  }

  permissionGranted(mediaStream: any) {
    var audioTrack = mediaStream.getAudioTracks()[0];
    if (audioTrack != null) {
      this.defaultAudioDeviceId = audioTrack.id;
      audioTrack.stop();
    }
    var videoTrack = mediaStream.getVideoTracks()[0];
    if (videoTrack != null) {
      this.defaultVideoDeviceId = videoTrack.id;
      var constraints = videoTrack.getConstraints();
      if (constraints && constraints.width && constraints.width.exact) {
        if (environment.loggingEnabled) console.log('devices video constraints', constraints.width.exact);
      }
      videoTrack.stop();
    }
    if (environment.loggingEnabled) console.log('devices permission granted');
    this.setAudioVideoInputOptionValues();
  }

  permissionDenied() {
    if (environment.loggingEnabled) console.log('Devices permission denied, please grant the access');
  }

  setAudioVideoInputOptionValues() {
    navigator.mediaDevices.enumerateDevices()
      .then((devices) => {
        devices.forEach((device: any) => {
          if (environment.loggingEnabled) console.log(device);
          if (device.kind == 'videoinput') {
            this.videoDevices.push(device);
            if (this.defaultVideoDeviceId == "") {
              this.defaultVideoDeviceId = device.deviceId;
            }
          } else if (device.kind == 'audiooutput') {
            this.audioOutDevices.push(device);
            if (this.defaultAudioDeviceId == "") {
              this.defaultAudioDeviceId = device.deviceId;
            }
          } else if (device.kind == 'audioinput') {
            this.audioInDevices.push(device);
          }
        });
        if (environment.loggingEnabled) console.log('audio device in use: ', this.defaultAudioDeviceId);
        if (environment.loggingEnabled) console.log('video device in use: ', this.defaultVideoDeviceId);
        this.checkAndDisableFlipButton();
      }).catch((err) => {
        if (environment.loggingEnabled) console.log(err.name + ": " + err.message);
      });
  }

  checkAndDisableFlipButton() {
    // check whether user has more cameras to switch
    if (this.videoDevices.length <= 1) {
      this.flipButtonEnabled = false;
    }
  }

  enableCallButton() {
    this.callButtonEnabled = true;
    this.hangButtonEnabled = false;
  }

  enableHangupButton() {
    this.callButtonEnabled = false;
    this.hangButtonEnabled = true;
  }

  disableCallButton() {
    this.callButtonEnabled = false;
    this.hangButtonEnabled = false;
  }


  // flipVideo() {
  //   if (this.localStream == null || this.videoDevices.length == 0) return;

  //   this.deviceIndex = (this.deviceIndex + 1) % this.videoDevices.length;
  //   this.videoConstraints.video.deviceId = this.videoDevices[this.deviceIndex].deviceId;

  //   this.localStream.getVideoTracks().forEach((t: any) => {
  //     t.stop();
  //   });
  //   // get a local stream again with new constraints, show it in a self-view and add it to be sent
  //   if (!this.localStream) {
  //     this.localStream = navigator.mediaDevices.getUserMedia(this.videoConstraints);
  //   }
  //   //this.localStream.getTracks().forEach((track: any) => this.peerConnection.addTrack(track, this.localStream));
  //   //this.muteAndAddLocalVideo();
  // }

  startVideoElements() {
    if (environment.loggingEnabled) console.log("Starting video call");
    try {
      this.isStarted = true;
      this.localVideoDisable = false;
      this.enableHangupButton();
    } catch (err) { }
  }

  stopVideoCall() {
    if (environment.loggingEnabled) console.log('Stop call initiated.');
    this.hangup();
  }

  setInitialVideoAndAudio() {
    this.audioElement = document.createElement('audio');
    this.audioElement.src = this.callerTune;
    this.audioElement.setAttribute('loop', 'true');
    this.audioElement.addEventListener('ended', this.startRinger);
  }

  // /////////////////////////////////////////////////////////

  startRinger() {
    this.audioElement.currentTime = 0;
    try {
      var promise = this.audioElement.play();
      if (promise !== undefined) {
        promise.then(res => {
          // Autoplay started!
        }).catch(error => {
          // Autoplay was prevented.
        });
      }
    }
    catch (error: any) {
      if (environment.loggingEnabled) console.log("Sound auto play restriction");
    }
  }

  stopRinger() {
    this.audioElement.pause();
    this.audioElement.currentTime = 0;
  }

  restartRinger() {
    if (environment.loggingEnabled) console.log('restarting the ringer');
    this.audioElement.currentTime = 0;
    this.audioElement.play();
  }

  setTune(tuneIndex: string) {
    this.callerTune = 'assets/tunes/tune' + tuneIndex + '.mp3';
    this.audioElement.setAttribute('src', this.callerTune);
  }

  acceptCall() {
    this.connectJanusVideoSession();
    this.startCallTimer();
    this.hideAcceptReject();
    this.enableHangupButton();
    this.stopRinger();
    localStorage.setItem('message', 'Video Started');
  }

  rejectCall() {
    this.hideAcceptReject();
    this.stopRinger();
    this.hangup();
  }

  hangup() {
    if (environment.loggingEnabled) console.log('Hanging up.');
    this.isStarted = true;
    this.localVideoDisable = false;
    var terminationMessage = this.createTerminationMessage();
    this.webSocketService.sendMessage(terminationMessage);
    // this.stopRemoteVideo();
    // this.stopLocalVideo();
    this.stopRinger();
    this.enableCallButton();
    this.closeVideoModal();
    this.terminateVideoSession();
    this.resetTimer();
  }

  handleRemoteHangup() {
    if (environment.loggingEnabled) console.log('Remote Session terminated.');
    this.isStarted = false;
    this.isStreamer = false;
    this.enableCallButton();
    this.stopRinger();
    this.hideAcceptReject();
    this.terminateVideoSession();
  }

  handleRemoteGoodBye() {
    if (environment.loggingEnabled) console.log('Remote peer is lost.');
    this.isStarted = false;
    this.isStreamer = false;
    this.disableCallButton();
    this.stopRinger();
    this.hideAcceptReject();
    this.terminateVideoSession();
    this.resetTimer();
  }

  // stopRemoteVideo() {
  //   if (environment.loggingEnabled) console.log('Stopping remote video.');
  //   this.isStarted = false;
  //   if (this.remoteStream != null) {
  //     this.remoteStream.getTracks().forEach((t: MediaStreamTrack) => {
  //       if (environment.loggingEnabled) console.log('stopping the track', t);
  //       t.stop();
  //     });
  //   }
  //   this.remoteStream = null;
  //   this.remoteVideo.nativeElement.srcObject = null;
  //   // this.remoteVideo.src = 'assets/clips/clip' + (Math.floor(Math.random() * 8) + 1) + '.mp4';
  //   // if (this.peerConnection != null) {
  //   //   this.peerConnection.close();
  //   //   this.peerConnection = null;
  //   // }
  // }

  // stopLocalVideo() {
  //   if (environment.loggingEnabled) console.log("Stopping local video");
  //   this.isStarted = false;
  //   if (this.localStream != null) {
  //     this.localStream.getTracks().forEach((t: MediaStreamTrack) => {
  //       if (environment.loggingEnabled) console.log('stopping the track', t);
  //       t.stop();
  //     });
  //   }
  //   this.localStream = null;
  //   //this.localVideo.nativeElement.srcObject = null;
  // }

  showAcceptReject() {
    if (environment.loggingEnabled) console.log('show accept reject now');
    this.startRinger();
    this.showAcceptRejectButton = true;
  }

  hideAcceptReject() {
    if (environment.loggingEnabled) console.log('hide accept reject now');
    this.showAcceptRejectButton = false;
  }

  //   setQuality(qualityWidth: number, qualityHeight: number) {

  //   this.videoWidth = qualityWidth;
  //   this.videoHeight = qualityHeight;
  //   this.videoConstraints.video.width.exact = qualityWidth;
  //   this.videoConstraints.video.height.exact = qualityHeight;
  // }

  //Mute/Unmute Button
  unmuteButton = document.querySelector('#unmuteButton');
  muteButton = document.querySelector('#muteButton');

  mute() {
    this.unmuteButtonDisplay = false;
    this.muteButtonDisplay = true;
    this.localHandle?.muteAudio(false);
    let muted = this.localHandle?.isAudioMuted();
    console.log("Mute: ", muted);
  }

  unMute() {
    this.muteButtonDisplay = false;
    this.unmuteButtonDisplay = true;
    this.localHandle?.unmuteAudio(false);
    let muted = this.localHandle?.isAudioMuted();
    console.log("Mute: ", muted);
  }

  speakerOff() {
    if (this.remoteAudio.nativeElement.srcObject && this.remoteAudio.nativeElement.srcObject.getAudioTracks()) {
      this.remoteAudio.nativeElement.srcObject.getAudioTracks().forEach((track: MediaStreamTrack) => {
        track.enabled = false;
      });
    }
    this.speakerOffButtonDisplay = true;
    this.speakerOnButtonDisplay = false;
  }

  speakerOn() {
    if (this.remoteAudio.nativeElement.srcObject && this.remoteAudio.nativeElement.srcObject.getAudioTracks()) {
      this.remoteAudio.nativeElement.srcObject.getAudioTracks().forEach((track: MediaStreamTrack) => {
        track.enabled = true;
      });
    }
    this.speakerOffButtonDisplay = false;
    this.speakerOnButtonDisplay = true;
  }

  // //submit button
  submitted() {
    var submitButton: HTMLElement = document.querySelector('#settingsButton') as HTMLElement;
    submitButton.click();
  }

  // //enable disable local div
  enableDisableLocalVideo(enabled: boolean) {
    // $("#localVideo").toggle();
  }

  // //flip screen 
  flipDisplay() {
    // $('.arrow-img').toggleClass('rotate');
    // $('.arrow-img').toggleClass('rotate2');
    // if ($('#localVideo').hasClass("local")) {
    // 	$('.local').attr('id', 'remoteVideo');
    // 	$('.remote').attr('id', 'localVideo');
    // } else {
    // 	$('.local').attr('id', 'localVideo');
    // 	$('.remote').attr('id', 'remoteVideo');
    // }
  }

  openAudioModal() {
    this.modalService.open('audio-call-modal');
  }

  closeAudioModal() {
    this.modalService.close('audio-call-modal');
  }

  openVideoModal() {
    this.modalService.open('video-call-modal');
  }

  closeVideoModal() {
    this.modalService.close('video-call-modal');
  }

  openAcceptRejectModal() {
    this.modalService.open('accept-reject-call-modal');
  }

  closeAcceptRejectModal() {
    this.modalService.close('accept-reject-call-modal');
  }



  /// #### JANUS Functions #######
  initializeJanusVideoServer() {
    Janus.init({
      debug: this.debug,
      callback: () => {
        if (!Janus.isWebrtcSupported()) {
          console.log("No WebRTC support... ");
          return;
        }

        this.session = new Janus({
          server: this.server,
          iceServers: this.iceServers,
          //token: this.token,
          apisecret: this.apiSecret,
          //iceTransportPolicy: this.icePolicy,
          success: () => {
            this.session.attach({
              plugin: "janus.plugin.videoroom",
              opaqueId: this.opaqueId,
              success: (pluginHandle: any) => this.localInitializationSuccessful(pluginHandle),
              error: (err: string) => this.initializationFailure(err),
              consentDialog: (on: boolean) => this.handleConstentDialog(on),
              webrtcState: (on: boolean) => this.handleLocalWebrtcState(on),
              iceState: (state: any) => this.handleIceState(state),
              mediaState: (medium: any, on: boolean, mid: any) => this.handleMediaState(medium, on, mid),
              slowLink: (uplink: any, lost: any, mid: any) => this.handleSlowLink(uplink, lost, mid),
              onmessage: (message: any, jsep: any) => this.handleLocalMessage(message, jsep),
              onlocaltrack: (track: MediaStreamTrack, on: boolean) => this.handlePublisherLocalTrack(track, on),
              onremotetrack: (track: MediaStreamTrack, mid: any, on: boolean, metaData: any) => this.handlePublisherRemoteTrack(track, mid, on, metaData),
              oncleanup: () => this.handlePublisherCleanup(),
              ondetached: () => this.handleDetached(),
              destroyed: () => this.handleDestroyed()
            });
          },
          error: (error: any) => {
            if (environment.loggingEnabled) console.log("error", error);
          },
          destroyed: () => {
            window.location.reload();
          }
        })
      }
    });
  }


  localInitializationSuccessful(pluginHandle: any) {
    this.localHandle = pluginHandle;
    if (environment.loggingEnabled) console.log("Local Plugin attached! (" + this.localHandle?.getPlugin() + ", id=" + this.localHandle?.getId() + ")");
    if (environment.loggingEnabled) console.log("  -- This is a publisher/manager", pluginHandle);
  }

  remoteInitializationSuccessful(pluginHandle: any) {
    this.remoteHandle = pluginHandle;
    if (environment.loggingEnabled) console.log("Remote Plugin attached! (" + this.localHandle?.getPlugin() + ", id=" + this.localHandle?.getId() + ")");
    if (environment.loggingEnabled) console.log("  -- This is a subscriber", pluginHandle);
  }

  isJanusConnected() {
    return this.session != null;
  }

  private initializationFailure(error: string) {
    if (environment.loggingEnabled) console.log("  -- Error attaching plugin...", error);
  }

  private handleConstentDialog(on: boolean) {
    if (environment.loggingEnabled) console.log('Consent Dialog', on);
  }

  private handleLocalWebrtcState(on: boolean) {
    if (environment.loggingEnabled) console.log("Janus says our WebRTC PeerConnection is " + (on ? "up" : "down") + " now");
    if (!on) {
      return false;
    }
    let bitRate = 0; //not capping bit rate
    this.localHandle?.send({ message: { request: "configure", bitrate: bitRate } });
    return false;
  }

  private handleRemoteWebrtcState(on: boolean) {
    if (environment.loggingEnabled) console.log("Janus says our WebRTC PeerConnection is " + (on ? "up" : "down") + " now");
    if (!on) {
      return false;
    }
    let bitRate = 0; //not capping bit rate
    this.remoteHandle?.send({ message: { request: "configure", bitrate: bitRate } });
    return false;
  }

  private handleIceState(state: any) {
    if (environment.loggingEnabled) console.log("ICE state changed to " + state);
  }

  private handleMediaState(medium: any, on: boolean, mid: any) {
    if (environment.loggingEnabled) console.log("Janus " + (on ? "started" : "stopped") + " receiving our " + medium + " (mid=" + mid + ")");
  }

  private handleSlowLink(uplink: any, lost: any, mid: any) {
    if (environment.loggingEnabled) console.log("Janus reports problems " + (uplink ? "sending" : "receiving") + " packets on mid " + mid + " (" + lost + " lost packets)");
  }

  private handlePublisherCleanup() {
    if (environment.loggingEnabled) console.log(" ::: Got a publisher cleanup notification: we are unpublished now :::");
    delete this.feedStreams[this.myId];
    this.localTracks = {};
    this.localVideos = 0;
  }

  private handleSubscriberCleanup() {
    if (environment.loggingEnabled) console.log(" ::: Got a subscriber cleanup notification: we are unpublished now :::");
    for (let i = 1; i < 6; i++) {
      if (this.bitrateTimer[i]) {
        clearInterval(this.bitrateTimer[i]);
      }
      if (this.bitrateTimer[i]) {
        this.bitrateTimer[i] = null;
      }
      if (this.feedStreams[i]) {
        this.feedStreams[i].simulcastStarted = false;
        this.feedStreams[i].svcStarted = false;
        this.feedStreams[i].remoteVideos = 0;
      }
    }
    this.remoteTracks = {};
  }

  private handleDetached() {
    if (environment.loggingEnabled) console.log("Detach occured");
  }

  private handleDestroyed() {
    if (environment.loggingEnabled) console.log("Destroy occured");
  }

  hangupLocalJanus() {
    this.localHandle?.hangup();
  }

  publishOwnFeed(useAudio: boolean) {
    if (this.localHandle != null) {
      let tracks = [];
      if (useAudio) {
        tracks.push({
          type: 'audio',
          capture: true,
          send: true,
          recv: false,
          transforms: { sender: this.senderTransforms['audio'] }
        });
      }

      tracks.push({
        type: 'video',
        capture: this.appConfig.RESOLUTION,
        send: true,
        recv: false,
        transforms: { sender: this.senderTransforms['video'] },
        // We may need to enable simulcast or SVC on the video track
        simulcast: this.doSimulcast,
        // We only support SVC for VP9 and (still WIP) AV1
        svc: ((this.vcodec === 'vp9' || this.vcodec === 'av1') && this.doSvc) ? this.doSvc : null,
      });

      tracks.push({
        type: 'data'
      });

      this.localHandle.createOffer(
        {
          tracks: tracks,
          success: (jsep: any) => {
            if (environment.loggingEnabled) console.log("Got publisher SDP!", jsep);
            let publish: any;
            if (!this.acodec && !this.vcodec) {
              publish = { request: "configure", audio: useAudio, video: true };
            } else if (this.acodec && !this.vcodec) {
              publish = { request: "configure", audio: useAudio, video: true, audiocodec: this.acodec };
            } else if (this.vcodec && !this.acodec) {
              publish = { request: "configure", audio: useAudio, video: true, videocodec: this.vcodec };
            } else if (this.vcodec && this.acodec) {
              publish = { request: "configure", audio: useAudio, video: true, audiocodec: this.acodec, videocodec: this.vcodec };
            }
            if (environment.loggingEnabled) console.log("Sending publish message", publish, jsep);
            this.localHandle?.send({ message: publish, jsep: jsep });
          },
          error: (error: any) => {
            if (environment.loggingEnabled) console.log("WebRTC error:", error);
            if (useAudio) {
              this.publishOwnFeed(true);
            } else {
              if (environment.loggingEnabled) console.log("WebRTC error... " + error.message);
            }
          }
        });

    }
  }


  createRoom(options?: VideoRoomCreateOptions) {
    const message = {
      request: 'create',
      "room": this.room,
      'secret': this.secret,
      'admin_key': this.roomAdminKey,
      'pin': this.pin,
      notify_joining: true,
      dummy_publisher: false,
      require_e2ee: true,
      ...options
    }
    if (this.localHandle != null) {
      this.localHandle.send({
        message: message, success: (response: any) => {
          if (environment.loggingEnabled) console.log("Room Created Response", response);
          //if(response["error_code"] === 427 && response["error"] === "Room "+this.room+ " already exists") {
          this.roomCreated = true;
          //}
          return true;
        }, error: (err: string) => {
          if (environment.loggingEnabled) console.log("Room Created Error", err);
          return this.roomCreated = false;;
        }
      });
    }
  }

  removeRoom() {
    const message = {
      request: 'destroy',
      "room": this.room,
      'secret': this.secret,
      'permanent': true
    }
    if (this.localHandle != null) {
      this.localHandle.send({
        message: message, success: (response: any) => {
          if (environment.loggingEnabled) console.log("Room Deleted Response", response);
          //if(response["error_code"] === 427 && response["error"] === "Room "+this.room+ " already exists") {
          // this.roomCreated = true;
          //}
          return true;
        }, error: (err: string) => {
          if (environment.loggingEnabled) console.log("Room Deleted Error", err);
          // return this.roomCreated = false;;
        }
      });
    }
  }

  listRooms() {
    const message = { request: 'list' }
    if (this.localHandle != null) {
      const response = this.localHandle.send({ message: message });
      return response;
    }
  }

  listParticipants() {
    const message = { "request": "listparticipants", "room": this.room }
    if (this.localHandle != null) {
      const response = this.localHandle.send({ message: message });
      return response?.participants;
    }
  }

  listPublishers() {
    const message = { "request": "listparticipants", "room": this.room }
    if (this.localHandle != null) {
      const response = this.localHandle.send({ message: message });
      return response?.participants?.filter((x: any) => x.publisher);
    }
  }

  joinAndConfigure(options: any) {
    const message = {
      request: "joinandconfigure",
      secret: this.secret,
      pin: this.pin,
      admin_key: this.roomAdminKey,
      ptype: "publisher",
      room: parseInt("" + this.room),
      display: this.loggedInUser.userName,
      //id: this.loggedInUser.id, 
      ...options
    }
    // attach's onmessage event
    if (this.localHandle != null) {
      const response = this.localHandle.send({ message: message });
      return response;
    }
  }

  joinRoom(options: any) {
    const message = {
      request: "join",
      ptype: "publisher",
      "pin": this.pin,
      room: parseInt("" + this.room),
      display: this.loggedInUser.userName,
      //id: this.loggedInUser.id, 
      ...options
    }
    if (this.localHandle != null) {
      const response = this.localHandle.send({ message: message });
      return response;
    }
  }


  handleLocalMessage(message: any, jsep: any) {
    if (environment.loggingEnabled) console.log("Publisher Event: message ", message, " jsep ", jsep);
    let event = message["videoroom"];
    if (event != undefined && event != null) {
      if (event === "joined") {
        // Publisher/manager created, negotiate WebRTC and attach to existing feeds, if any
        this.myId = message["id"];
        this.myPVTId = message["private_id"];
        this.publishOwnFeed(true);
        // Any new feed to attach to?
        if (message["publishers"]) {
          let list = message["publishers"];
          let sources = null;
          for (let f in list) {
            if (list[f]["dummy"]) {
              continue;
            }
            let id = list[f]["id"];
            let display = list[f]["display"];
            let streams = list[f]["streams"];
            for (let i in streams) {
              let stream = streams[i];
              stream["id"] = id;
              stream["display"] = display;
            }
            let slot = this.feedStreams[id] ? this.feedStreams[id].slot : null;
            let remoteVideos = this.feedStreams[id] ? this.feedStreams[id].remoteVideos : 0;
            this.feedStreams[id] = {
              id: id,
              display: display,
              streams: streams,
              slot: slot,
              remoteVideos: remoteVideos
            }
            if (environment.loggingEnabled) console.log("  >> [" + id + "] " + display + ":", streams);
            if (!sources) {
              sources = [];
            }
            sources.push(streams);
          }
          if (sources) {
            this.subscribeTo(sources);
          }
        }
      } else if (event === "destroyed") {
        // The room has been destroyed
        if (environment.loggingEnabled) console.log("The room has been destroyed!");
      } else if (event === "event") {
        //this.listroom();
        // Any info on our streams or a new feed to attach to?
        if (message["streams"]) {
          let streams = message["streams"];
          for (let i in streams) {
            let stream = streams[i];
            stream["id"] = this.loggedInUser.id;
            stream["display"] = this.loggedInUser.userName;
          }
          this.feedStreams[this.loggedInUser.id] = {
            id: this.loggedInUser.id,
            display: this.loggedInUser.userName,
            streams: streams
          }
        } else if (message["publishers"]) {
          let list = message["publishers"];
          let sources = null;
          for (let f in list) {
            if (list[f]["dummy"])
              continue;
            let id = list[f]["id"];
            let display = list[f]["display"];
            let streams = list[f]["streams"];
            for (let i in streams) {
              let stream = streams[i];
              stream["id"] = id;
              stream["display"] = display;
            }
            let slot = this.feedStreams[id] ? this.feedStreams[id].slot : null;
            let remoteVideos = this.feedStreams[id] ? this.feedStreams[id].remoteVideos : 0;
            this.feedStreams[id] = {
              id: id,
              display: display,
              streams: streams,
              slot: slot,
              remoteVideos: remoteVideos
            }
            if (environment.loggingEnabled) console.log("  >> [" + id + "] " + display + ":", streams);
            if (!sources) {
              sources = [];
            }
            sources.push(streams);
          }
          if (sources) {
            this.subscribeTo(sources);
          }
        } else if (message["leaving"]) {
          // One of the publishers has gone away?
          let leaving = message["leaving"];
          if (environment.loggingEnabled) console.log("Publisher left: " + leaving);
          this.unsubscribeFrom(leaving);
        } else if (message["unpublished"]) {
          // One of the publishers has unpublished?
          let unpublished = message["unpublished"];
          if (environment.loggingEnabled) console.log("Publisher left: " + unpublished);
          if (unpublished === 'ok') {
            // That's us
            this.hangupLocalJanus();
            return;
          }
          this.unsubscribeFrom(unpublished);
        } else if (message["error"]) {
          if (message["error_code"] === 426) {
            // This is a "no such room" error: give a more meaningful description
            if (environment.loggingEnabled) console.log("Apparently room <code>" + this.room + "</code> does not exist.");
          } else {
            if (environment.loggingEnabled) console.log(message["error"]);
          }
        }
      }
      if (jsep) {
        if (environment.loggingEnabled) console.log("Handling SDP as well...", jsep);
        if (jsep !== undefined && jsep !== null && message?.videoroom !== 'attached') {
          if (environment.loggingEnabled) console.log("calling publisher remote description");
          this.localHandle?.handleRemoteJsep({ jsep: jsep });
        }
        // Check if any of the media we wanted to publish has been rejected (e.g., wrong or unsupported codec)
        let audio = message["audio_codec"];
        if (audio) {
          this.acodec = audio;
        }
        let video = message["video_codec"];
        if (video) {
          this.vcodec = video;
        }
        if (environment.loggingEnabled) console.log(audio, video);
      }
    }
  }

  handleRemoteMessage(message: any, jsep: any) {
    if (environment.loggingEnabled) console.log(" ::: Got a message (subscriber) :::", message);
    let event = message["videoroom"];
    if (message["error"]) {
    } else if (event) {
      if (event === "attached") {
        // Now we have a working subscription, next requests will update this one
        this.creatingSubscription = false;
        if (environment.loggingEnabled) console.log("Successfully attached to feed in room " + message["room"]);
      } else if (event === "event") {
        // Check if we got an event on a simulcast-related event from this publisher
        let mid = message["mid"];
        let substream = message["substream"];
        let temporal = message["temporal"];
        if ((substream !== null && substream !== undefined) || (temporal !== null && temporal !== undefined)) {
          // Check which this feed this refers to
          let slot = this.slots[mid];
          if (!this.simulcastStarted[slot]) {
            this.simulcastStarted[slot] = true;
          }
        }
        // Or maybe SVC?
        let spatial = message["spatial_layer"];
        temporal = message["temporal_layer"];
        if ((spatial !== null && spatial !== undefined) || (temporal !== null && temporal !== undefined)) {
          let slot = this.slots[mid];
          if (!this.svcStarted[slot]) {
            this.svcStarted[slot] = true;
          }
        }
      } else {
        // What has just happened?
      }
    }
    if (message["streams"]) {
      // Update map of subscriptions by mid
      for (let i in message["streams"]) {
        let mid = message["streams"][i]["mid"];
        this.subStreams[mid] = message["streams"][i];
        let feed = this.feedStreams[message["streams"][i]["feed_id"]];
        if (feed && feed.slot) {
          this.slots[mid] = feed.slot;
          this.mids[feed.slot] = mid;
        }
      }
    }
    if (jsep) {
      if (environment.loggingEnabled) console.log("Handling SDP as well...", jsep);
      // Answer and attach 
      let tracks = [];
      tracks.push({ type: 'data' });
      tracks.push({
        type: 'audio',
        capture: false,
        send: false,
        recv: true,
        transforms: { receiver: this.receiverTransforms['audio'] }
      });
      tracks.push({
        type: 'video',
        capture: this.appConfig.RESOLUTION,
        send: false,
        recv: true,
        transforms: { receiver: this.receiverTransforms['video'] },
        // We may need to enable simulcast or SVC on the video track
        simulcast: this.doSimulcast,
        // We only support SVC for VP9 and (still WIP) AV1
        svc: ((this.vcodec === 'vp9' || this.vcodec === 'av1') && this.doSvc) ? this.doSvc : null,
      });

      this.remoteHandle?.createAnswer({
        jsep: jsep,
        // We only specify data channels here, as this way in case they were offered we'll enable them. 
        // Since we don't mention audio or video tracks, we autoaccept them as recvonly (since we won't capture anything ourselves)
        tracks: tracks,
        success: (jsep) => {
          if (environment.loggingEnabled) console.log("Got SDP!", jsep);
          let message = { request: "start", room: parseInt("" + this.room), pin: this.pin };
          const response = this.remoteHandle?.send({ message, jsep });
          if (environment.loggingEnabled) console.log("Answer created ", response);
        },
        error: function (error) {
          if (environment.loggingEnabled) console.log("WebRTC error:", error);
        }
      });
    }
  }

  disconnect() {
    if (environment.loggingEnabled) console.log("Disconnect called");
    if (this.localVideo && this.localVideo.nativeElement && this.localVideo.nativeElement.srcObject) {
      this.localVideo.nativeElement.srcObject.getVideoTracks().forEach((track: MediaStreamTrack) => {
        track.stop()
        this.localVideo.nativeElement.srcObject.removeTrack(track);
      });
    }
    if (this.remoteAudio && this.remoteAudio.nativeElement && this.remoteAudio.nativeElement.srcObject) {
      this.remoteAudio.nativeElement.srcObject.getAudioTracks().forEach((track: MediaStreamTrack) => {
        track.stop()
        this.remoteAudio.nativeElement.srcObject.removeTrack(track);
      });
    }
    if (this.remoteVideo && this.remoteVideo.nativeElement && this.remoteVideo.nativeElement.srcObject) {
      this.remoteVideo.nativeElement.srcObject.getVideoTracks().forEach((track: MediaStreamTrack) => {
        track.stop()
        this.remoteVideo.nativeElement.srcObject.removeTrack(track);
      });
    }
    this.localTracks = {};
    this.remoteTracks = {};
  }

  shutdown() {
    if (this.session) {
      if (this.localHandle) {
        this.localHandle.detach()
        this.localHandle = null;
      }
      if (this.remoteHandle) {
        this.remoteHandle.detach()
        this.remoteHandle = null;
      }
      // this.session.destroy({ cleanupHandles: true })
    }
  }

  checkIfRoomExists() {
    const message = { "request": "exists", "room": parseInt("" + this.room) };
    if (this.localHandle != null) {
      this.localHandle.send({
        message: message, success: (response: any) => {
          this.roomExists = response?.exists;
        }, error(err: string) {
          return false;
        }
      });
    }
  }

  destroy() {
    if (this.localHandle != null) {
      const message = { request: "destroy", room: parseInt("" + this.room), secret: this.secret, admin_key: this.roomAdminKey }
      return this.localHandle.send({ message: message });
    }
  }

  leave() {
    if (this.localHandle != null) {
      const message = { "request": "leave", "room": this.room }
      return this.localHandle.send({ message: message });
    }
  }


  toggleMute() {
    let muted = this.localHandle?.isAudioMuted();
    if (environment.loggingEnabled) console.log((muted ? "Unmuting" : "Muting") + " local stream...");
    if (muted)
      this.localHandle?.unmuteAudio(false);
    else
      this.localHandle?.muteAudio(false);
    muted = this.localHandle?.isAudioMuted();
    console.log("Mute: ", muted);
  }

  unpublishOwnFeed() {
    // Unpublish our stream
    if (environment.loggingEnabled) console.log("Unpublishing our own feed");
    let unpublish = { request: "unpublish" };
    this.localHandle?.send({ message: unpublish });
  }

  creatingSubscription = false;
  subscribeTo(sources: any) {
    if (environment.loggingEnabled) console.log("Creating subscription");
    // Check if we're still creating the subscription handle
    if (this.creatingSubscription) {
      // Still working on the handle, send this request later when it's ready
      setTimeout(() => {
        this.subscribeTo(sources);
      }, 500);
      return;
    }
    // If we already have a working subscription handle, just update that one
    if (this.remoteHandle) {
      // Prepare the streams to subscribe to, as an array: we have the list of streams the feeds are publishing, 
      // so we can choose what to pick or skip
      let added = null, removed = null;
      for (let s in sources) {
        let streams = sources[s];
        for (let i in streams) {
          let stream = streams[i];
          // If the publisher is VP8/VP9 and this is an older Safari, let's avoid video
          if (stream.type === "video" && Janus.webRTCAdapter.browserDetails.browser === "safari" &&
            ((stream.codec === "vp9") || (stream.codec === "vp8"))) {
            if (environment.loggingEnabled) console.log("Publisher is using " + stream.codec.toUpperCase + ", but Safari doesn't support it: disabling video stream #" + stream.mindex);
            continue;
          }
          if (stream.disabled) {
            if (environment.loggingEnabled) console.log("Disabled stream:", stream);
            // Unsubscribe
            if (!removed)
              removed = [];
            removed.push({
              feed: stream.id,	// This is mandatory
              mid: stream.mid		// This is optional (all streams, if missing)
            });
            delete this.subscriptions[stream.id][stream.mid];
            continue;
          }
          if (this.subscriptions[stream.id] && this.subscriptions[stream.id][stream.mid]) {
            if (environment.loggingEnabled) console.log("Already subscribed to stream, skipping:", stream);
            continue;
          }
          // Find an empty slot in the UI for each new source
          if (!this.feedStreams[stream.id].slot) {
            let slot;
            for (let i = 1; i < 6; i++) {
              if (!this.feeds[i]) {
                slot = i;
                this.feeds[slot] = stream.id;
                this.feedStreams[stream.id].slot = slot;
                this.feedStreams[stream.id].remoteVideos = 0;
                break;
              }
            }
          }
          // Subscribe
          if (!added) {
            added = [];
          }
          added.push({
            feed: stream.id,	// This is mandatory
            mid: stream.mid		// This is optional (all streams, if missing)
          });
          if (!this.subscriptions[stream.id]) {
            this.subscriptions[stream.id] = {};
          }
          this.subscriptions[stream.id][stream.mid] = true;
        }
      }
      if ((!added || added.length === 0) && (!removed || removed.length === 0)) {
        // Nothing to do
        return;
      }
      if (added) {
        let message = { request: 'update', 'subscribe': added, pin: this.pin };
        const response = this.remoteHandle?.send({ message });
        if (environment.loggingEnabled) console.log("Room updated ", response);
      }
      if (removed) {
        let message = { request: 'update', 'unsubscribe': removed, pin: this.pin };
        const response = this.remoteHandle?.send({ message });
        if (environment.loggingEnabled) console.log("Room updated ", response);
      }

      // Nothing else we need to do
      return;
    }

    // If we got here, we're creating a new handle for the subscriptions (we only need one)
    this.creatingSubscription = true;
    this.session.attach({
      plugin: "janus.plugin.videoroom",
      opaqueId: this.opaqueId,
      success: (pluginHandle: any) => {
        this.remoteInitializationSuccessful(pluginHandle);
        // Prepare the streams to subscribe to, as an array: we have the list of streams the feed is publishing, so we can choose what to pick or skip
        let subscription = [];
        for (let s in sources) {
          let streams = sources[s];
          for (let i in streams) {
            let stream = streams[i];
            // If the publisher is VP8/VP9 and this is an older Safari, let's avoid video
            if (stream.type === "video" && Janus.webRTCAdapter.browserDetails.browser === "safari" &&
              ((stream.codec === "vp9") || (stream.codec === "vp8"))) {
              if (environment.loggingEnabled) console.log("Publisher is using " + stream.codec.toUpperCase + ", but Safari doesn't support it: disabling video stream #" + stream.mindex);
              continue;
            }
            if (stream.disabled) {
              if (environment.loggingEnabled) console.log("Disabled stream:", stream);
              // TODO Skipping for now, we should unsubscribe
              continue;
            }
            if (environment.loggingEnabled) console.log("Subscribed to " + stream.id + "/" + stream.mid + "?", this.subscriptions);
            if (this.subscriptions[stream.id] && this.subscriptions[stream.id][stream.mid]) {
              if (environment.loggingEnabled) console.log("Already subscribed to stream, skipping:", stream);
              continue;
            }
            // Find an empty slot in the UI for each new source
            if (!this.feedStreams[stream.id].slot) {
              let slot;
              for (let i = 1; i < 6; i++) {
                if (!this.feeds[i]) {
                  slot = i;
                  this.feeds[slot] = stream.id;
                  this.feedStreams[stream.id].slot = slot;
                  this.feedStreams[stream.id].remoteVideos = 0;
                  break;
                }
              }
            }
            subscription.push({
              feed: stream.id,	// This is mandatory
              mid: stream.mid		// This is optional (all streams, if missing)
            });
            if (!this.subscriptions[stream.id]) {
              this.subscriptions[stream.id] = {};
            }
            this.subscriptions[stream.id][stream.mid] = true;
          }
        }
        // We wait for the plugin to send us an offer
        let message = {
          request: "join",
          room: parseInt("" + this.room),
          pin: this.pin,
          ptype: "subscriber",
          streams: subscription,
          use_msid: this.useMSId,
          private_id: this.myPVTId
        };
        this.remoteHandle?.send({ message });
      },
      error: function (error) {
        if (environment.loggingEnabled) console.log("  -- Error attaching plugin...", error);
      },
      iceState: (state) => this.handleIceState(state),
      webrtcState: (on: boolean) => this.handleRemoteWebrtcState(on),
      slowLink: (uplink: any, lost: any, mid: any) => this.handleSlowLink(uplink, lost, mid),
      onmessage: (msg: any, jsep: any) => this.handleRemoteMessage(msg, jsep),
      onlocaltrack: (track: MediaStreamTrack, on: boolean) => this.handleSubscriberLocalTrack(track, on),
      onremotetrack: (track: any, mid: any, on: any, metadata: any) => this.handleSubscriberRemoteTrack(track, mid, on, metadata),
      oncleanup: () => this.handleSubscriberCleanup(),
      destroyed: () => this.handleDestroyed()
    });
  }

  unsubscribeFrom(id: any) {
    // Unsubscribe from this publisher
    let feed = this.feedStreams[id];
    if (!feed)
      return;
    if (environment.loggingEnabled) console.log("Feed " + id + " (" + feed.display + ") has left the room, detaching");
    if (this.bitrateTimer[feed.slot])
      clearInterval(this.bitrateTimer[feed.slot]);
    this.bitrateTimer[feed.slot] = null;
    delete this.simulcastStarted[feed.slot];
    delete this.svcStarted[feed.slot];
    delete this.feeds[feed.slot];
    this.feeds.slot = 0;
    delete this.feedStreams[id];
    // Send an unsubscribe request
    let unsubscribe = {
      request: "unsubscribe",
      pin: this.pin,
      streams: [{ feed: id }]
    };
    if (this.remoteHandle != null) {
      this.remoteHandle.send({ message: unsubscribe });
    }
    delete this.subscriptions[id];
  }

  // Helper to parse query string
  getQueryStringValue(name: string) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    let regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
      results = regex.exec(location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
  }

  // Helper to escape XML tags
  escapeXmlTags(value: string) {
    if (value) {
      let escapedValue = value.replace(new RegExp('<', 'g'), '&lt');
      escapedValue = escapedValue.replace(new RegExp('>', 'g'), '&gt');
      return escapedValue;
    }
    return "";
  }

  senderTransforms: any = {};
  receiverTransforms: any = {};
  encoder = new TextEncoder();
  decoder = new TextDecoder("utf-8");

  frameTypeToCryptoOffset = {
    key: 10,
    delta: 3,
    undefined: 1,
  };

  scount = 0;
  encodeFunction(encodedFrame: any, controller: any) {
    if (this.encryptionKey) {
      var decodedString = this.arrayBufferToString(encodedFrame.data);
      const encryptedData: string = EncryptionUtil.encrypt(this.encryptionKey, decodedString);
      var newData = this.stringToArrayBuffer(encryptedData);
      encodedFrame.data = newData;
    }
    controller.enqueue(encodedFrame);
  }

  rcount = 0;
  decodeFunction(encodedFrame: any, controller: any) {
    var decodedString = this.arrayBufferToString(encodedFrame.data);
    const decryptedData: string = EncryptionUtil.decrypt(this.encryptionKey, decodedString);
    var newData = this.stringToArrayBuffer(decryptedData);
    encodedFrame.data = newData;

    controller.enqueue(encodedFrame);
  }

  arrayBufferToString = (buffer: ArrayBuffer): string => {
    return this.BinaryToString(String.fromCharCode.apply(null, Array.prototype.slice.apply(new Uint8Array(buffer))));
  };

  stringToArrayBuffer = (string: string): ArrayBuffer => {
    return this.stringToUint8Array(string).buffer;
  };

  BinaryToString(binary: any) {
    var error;
    try {
      return decodeURIComponent(escape(binary));
    } catch (_error) {
      error = _error;
      if (error instanceof URIError) {
        return binary;
      } else {
        throw error;
      }
    }
  }

  stringToBinary(string: string) {
    var chars, code, i, isUCS2, len, _i;
    len = string.length;
    chars = [];
    isUCS2 = false;
    for (i = _i = 0; 0 <= len ? _i < len : _i > len; i = 0 <= len ? ++_i : --_i) {
      code = String.prototype.charCodeAt.call(string, i);
      if (code > 255) {
        isUCS2 = true;
        chars = null;
        break;
      } else {
        chars.push(code);
      }
    }
    if (isUCS2 === true) {
      return unescape(encodeURIComponent(string));
    } else {
      return String.fromCharCode.apply(null, Array.prototype.slice.apply(chars));
    }
  }

  stringToUint8Array(string: string) {
    var binary, binLen, buffer, chars, i, _i;
    binary = this.stringToBinary(string);
    binLen = binary.length;
    buffer = new ArrayBuffer(binLen);
    chars = new Uint8Array(buffer);
    for (i = _i = 0; 0 <= binLen ? _i < binLen : _i > binLen; i = 0 <= binLen ? ++_i : --_i) {
      chars[i] = String.prototype.charCodeAt.call(binary, i);
    }
    return chars;
  }

  initializeTransformMethods() {
    for (var trackKind of ["audio", "video"]) {
      this.senderTransforms[trackKind] = new TransformStream({
        start() {
          // Called on startup.
          console.log("[Sender transform)] Startup");
        },
        transform: (encodeFrame: any, controller: any) => this.encodeFunction(encodeFrame, controller)
      });
      this.receiverTransforms[trackKind] = new TransformStream({
        start() {
          // Called on startup.
          console.log("[Receiver transform] Startup");
        },
        transform: (decodeFrame: any, controller: any) => this.decodeFunction(decodeFrame, controller),
        flush() {
          // Called when the stream is about to be closed
          console.log("[Receiver transform] Closing");
        }
      });
    }
  }
}


<div class="main-wrapper">
    <div class="content main_content">
      <chat-sidebar-menu (outputMessage)="logoutEvent($event)" [selectedTab]="selectedTab" [loggedInUser]="loggedInUser"></chat-sidebar-menu>
      <div class="sidebar-group left-sidebar chat_sidebar" [class.hide-left-sidebar]="hideLeftSideBar">
        <div id="chats" class="left-sidebar-wrap sidebar active slimscroll">
          <div class="slimscroll">
            <div class="left-chat-title d-flex justify-content-start align-items-center">
              <!-- <div class="me-2"><a (click)="navigation.back()" class="clr_blue"><span><i
                                          class="fa fa-arrow-left" aria-hidden="true"></i></span></a></div> -->
              <div class="chat-title mb-2 margin-top-15">
                <h4 class="main-heading-new">CHATS</h4>
              </div>
            </div>
            <div class="search_chat has-search ">
              <span class="fas fa-search form-control-feedback"></span>
              <input class="form-control chat_input" id="search-contacts" type="text" placeholder="Search Chat Members" [(ngModel)]="searchTerm" autocomplete="off">
            </div>
            <div class="sidebar-body" id="chatsidebar">
             
              <div class="scrollbar" id="scrollbar4">
                <ul class="user-list mt-2 " *ngIf="chatMembers && chatMembers.length > 0">
                  <li *ngFor="let connection of chatMembers | chatMemberSearchFilter: searchTerm" (click)="setSelectedUser(connection)" class="user-list-item" [class.item-typing]="connection.isTyping" [class.selected-user]="connection.userId == selectedUser?.userId">
                    <div *ngIf="!isEmpty(connection.img)" class="avatar" [class.avatar-away]="!connection.online && !connection.dnd" [class.avatar-online]="connection.online && !connection.dnd" [class.avatar-offline]="connection.dnd">
                      <img [src]="connection.img" class="rounded-circle" alt="image">
                    </div>
                    <div *ngIf="isEmpty(connection.img)" class="avatar" [class.avatar-away]="!connection.online && !connection.dnd" [class.avatar-online]="connection.online && !connection.dnd" [class.avatar-offline]="connection.dnd">
                      <div class="letter-avatar">
                        {{getFirstLastLetter(connection)}}
                      </div>
                    </div>
                    <div class="users-list-body">
                      <div>
                        <h5 appHighlight [content]="connection.firstName + ' ' + connection.lastName" [searchTerm]="searchTerm" [caseSensitive]="false"></h5>
                        <p *ngIf="!connection.isTyping && connection.lastMessage">
                          {{connection.lastMessage}}
                        </p>
                        <p *ngIf="connection.isTyping">
                          <span class="animate-typing-col">
                            <span class="msg">typing&nbsp;</span>
                            <span class="dot"></span>
                            <span class="dot"></span>
                            <span class="dot"></span>
                          </span>
                        </p>
                      </div>
                      <div class="last-chat-time">
                        <small *ngIf="connection.online && !connection.dnd" class="online"> online </small>
                        <small *ngIf="!connection.online && !connection.dnd && connection.lastSeenTime != undefined" class="text-muted">{{connection.lastSeenTime | lastSeenTime}}</small>
                        <small *ngIf="connection.dnd" class="dnd"> DnD </small>
                        <div *ngIf="connection.unseenMsgCount && connection.unseenMsgCount > 0" class="new-message-count">
                          {{connection.unseenMsgCount}}
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                <div *ngIf="!chatMembers || chatMembers.length == 0">
                  <img class="noRecentChantimg" src="assets/images/nchat.png" />
                  <div class="chat-title">
                    <h4 class="mb-4 mt-2 text-center startChat nochatheading">No recent chat found</h4>
                    <!-- <h4 class="mb-4 mt-4 text-center startChat">Chat, call with your connections with high security make connections</h4> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="chat" [class.show-chatbar]="hideLeftSideBar" id="middle" *ngIf="nonEmpty(selectedUser) && isWebSocketOpen()">
        <div class="slimscroll">
          <div class="chat-header">
            <div class="user-details">
              <div class="d-lg-none ms-2">
                <ul class="list-inline mt-2 me-2">
                  <li class="list-inline-item">
                    <a class="text-muted px-0 left_side" (click)="backToChatMember()" data-chat="open">
                      <i class="fas fa-arrow-left"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <figure *ngIf="!isEmpty(selectedUser?.img)" class="avatar ms-1" [class.avatar-away]="!selectedUser?.online && !selectedUser?.dnd" [class.avatar-online]="selectedUser?.online && !selectedUser?.dnd" [class.avatar-offline]="selectedUser?.dnd">
                <img [src]="selectedUser?.img" class="rounded-circle" alt="image">
              </figure>
              <figure *ngIf="isEmpty(selectedUser?.img)" class="avatar newAvtar" [class.avatar-away]="!selectedUser?.online && !selectedUser?.dnd" [class.avatar-online]="selectedUser?.online && !selectedUser?.dnd" [class.avatar-offline]="selectedUser?.dnd">
                <div class="letter-avatar">
                  {{getFirstLastLetter(selectedUser)}}
                </div>
              </figure>
              <div class="mt-1">
                <h5>{{selectedUser?.firstName}} {{selectedUser?.lastName}}</h5>
                <div *ngIf="selectedUser.isTyping" class="mt-1">
                  <p *ngIf="selectedUser.isTyping">
                    <span class="animate-typing-col">
                      <span class="msg">typing&nbsp;</span>
                      <span class="dot"></span>
                      <span class="dot"></span>
                      <span class="dot"></span>
                    </span>
                  </p>
                </div>
                <div *ngIf="!selectedUser.isTyping" class="mt-1">
                  <small *ngIf="!selectedUser?.dnd && selectedUser?.online" class="online"> online </small>
                  <small *ngIf="!selectedUser?.dnd && !selectedUser?.online && selectedUser?.lastSeenTime != undefined">Last seen {{selectedUser?.lastSeenTime | customDatePipe}} {{lastSeenTime(selectedUser?.lastSeenTime)}}</small>
                  <small *ngIf="selectedUser?.dnd" class="dnd"> Do not disturb </small>
                </div>
              </div>
            </div>
            <div class="chat-options">
              <ul class="list-inline">
                <!-- <li class="list-inline-item" data-bs-toggle="tooltip" data-bs-placement="bottom"
                                  title="Search"><input type="search" class="search-field" placeholder="Search here" name="s"
                                      title="Search in messages" [(ngModel)]="searchInMessage" (focusout)="resetMsgTxt()"
                                      autocomplete="off" /></li> -->
                <li class="list-inline-item" data-bs-toggle="tooltip" data-bs-placement="bottom" title="" data-bs-original-title="Search" (click)="showSearchBox()">
                  <a href="javascript:void(0)" class="btn btn-outline-light chat-search-btn" (click)="showSearchBox()">
                    <i class="fas fa-search"></i>
                  </a>
                </li>
                <li class="list-inline-item" data-bs-toggle="tooltip" data-bs-placement="bottom" title="" data-bs-original-title="Audio call" (click)="initiateAudioCall()">
                  <a href="javascript:void(0)" class="btn btn-outline-light">
                    <i class="fas fa-phone"></i>
                  </a>
                </li>
                <li class="list-inline-item" data-bs-toggle="tooltip" data-bs-placement="bottom" title="" data-bs-original-title="Video call" (click)="initiateVideoCall()">
                  <a href="javascript:void(0)" class="btn btn-outline-light">
                    <i class="fas fa-video"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div class="chat-search" id="chat-search">
              <form>
                <span class="fas fa-search form-control-feedback"></span>
                <input type="text" name="chat-search" placeholder="Search Chats" class="form-control" [(ngModel)]="searchInMessage">
                <div class="close-btn-chat" (click)="hideSearchBox();resetMsgTxt();">
                  <span class="material-icons">close</span>
                </div>
              </form>
            </div>
          </div>
          <div class="scrollbar" id="scrollbar7" #messageAutoScroll>
            <div class="scroll-shrink-msg">
              <div class="chat-body">
                <div class="messages">
                  <!-- <div *ngIf="chatLoading" class="chat-loader mb-25"><div class="spinner-border text-primary" role="status"><span class="sr-only">Loading...</span></div></div> -->
                  <div *ngIf="chatLoading || moreChatLoading" class="center mb-25">
                    <p class="loader">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp; Loading Messages...
                    </p>
                  </div>
                  <div *ngIf="isMoreMessages() && !moreChatLoading" class="center mb-25">
                    <div (click)="loadMoreMessages()">
                      <p class="more-button">
                        <img width="13" height="13" src="assets/images/loading.png" alt=""> Load More Messages
                      </p>
                    </div>
                  </div>
                  <div class="chats" *ngFor="let message of publishedMessage" [class.chats-right]="loggedInUser.id === message.from">
                    <div class="chat-avatar" *ngIf="loggedInUser.id === message.to">
                      <div class="avatar">
                        <div class="letter-avatar-small">
                          {{getFirstLastLetterFromFullName(message.fromUserName)}}
                        </div>
                      </div>
                    </div>
                    <div class="chat-content">
                      <div class="message-content">
                        <span *ngIf="isMessageDecrypted(message)" appHighlight [content]="message.message" [searchTerm]="searchInMessage" [caseSensitive]="false"></span>
                        <span class="fw-400" *ngIf="!isMessageDecrypted(message)">Decrypting content...</span>
                        <span class="chat-time" *ngIf="isMessageDecrypted(message)">
                          <span class="time">
                            <i class="fas fa-clock"></i>
                            {{lastSeenTime(message.creationTime)}}
                          </span>
                        </span>
                      </div>
                      <!-- <div class="chat-profile-name"><h6>{{message.fromUserName}}</h6></div> -->
                    </div>
                    <div class="chat-avatar" *ngIf="loggedInUser.id === message.from">
                      <!-- <img src="assets/images/avatar/avatar-12.jpg" class="rounded-circle dreams_chat"
                                              alt="image"> -->
                      <div class="avatar">
                        <div class="letter-avatar-small">
                          {{getFirstLastLetterFromFullName(message.fromUserName)}}
                        </div>
                      </div>
                    </div>
                    <div class="chat-action-btns ms-3">
                      <div class="chat-action-col">
                        <a class="#" href="#" data-bs-toggle="dropdown">
                          <i class="fas fa-ellipsis-h"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end">
                          <a (click)="copyMessage(message.message)" class="dropdown-item dream_profile_menu">Copy <span>
                              <i class="far fa-copy"></i>
                            </span>
                          </a>
                          <a (click)="editMessage(message)" class="dropdown-item dream_profile_menu">Edit <span>
                              <i class="far fa-edit"></i>
                            </span>
                          </a>
                          <a (click)="deleteMessage(message)" class="dropdown-item">Delete <span>
                              <i class="far fa-trash-alt"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="chat-footer">
          <form (ngSubmit)="createMessage()" [class.disabled]="selectedUser?.dnd">
            <input autocomplete="off" type="text" name="message" [(ngModel)]="message" (keypress)="sendTypeIndicator()" class="form-control chat_form" placeholder="Type your message here...">
            <div class="form-buttons">
              <button class="btn send-btn" type="submit">
                <span class="material-icons">send</span>
              </button>
            </div>
          </form>
        </div>
      </div>
      <!-- WebSocket is disconnected -->
      <div class="center" *ngIf="(isWebSocketClosed() || isWebSocketConnecting()) && (chatMembers && chatMembers.length > 0)">
        <div>
          <img src="assets/images/connection.png" alt="">
        </div>
        <div>
          <p *ngIf="isWebSocketClosed()">Looks like secure connection is closed</p>
          <p *ngIf="isWebSocketConnecting()">Secure connection in progress...</p>
          <div class="mt-3">
            <button class="btn btn-update" (click)="reconnect()" [class.disabled]="isWebSocketConnecting()">
              <span *ngIf="isWebSocketClosed()">Reconnect</span>
              <span *ngIf="isWebSocketConnecting()">Connecting...</span>
            </button>
          </div>
        </div>
      </div>
      <!-- No user selected -->
      <div class="chat status-middle-bar d-flex align-items-center justify-content-center" *ngIf="!chatMembers || chatMembers.length == 0">
        <div class="status-right">
          <div>
            <img class="nodata2" src="assets/images/nodata2.png" alt="">
          </div>
          <div>
            <p>Select a contact from connection to start chat</p>
              <a class="colorBlue" (click)="goToConnection()">Go to Connection</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="appConfig">
    <app-video-play [selectedUser]="selectedUser" [appConfig]="appConfig" [eventEmitter]="parentEmitter"></app-video-play>
  </div>
  <loader [message]="loaderMessage"></loader>
import * as sjcl from 'sjcl';
declare var jQuery: any;

export class EncryptionUtil {

    // private static iterationCount = 1000;
    // private static keySize = 128;
    // private static iv = "5olvvndy2od23jThveFRyw==";
    // private static salt = "parYv9E1LYM=";

    private static options = { iter: 1000, ks: 128, ts: 64, iv: "5olvvndy2od23jThveFRyw==", salt: "parYv9E1LYM=" } as any;

    static encryptMe = (password: string, message: string) => {
        //Encrypt
        var encryptedMessage = sjcl.encrypt(password, message, this.options) as any;
        var parsedMessage = JSON.parse(encryptedMessage);
        var prop;
        for (prop in this.options) {
            delete parsedMessage[prop];
        }
        return parsedMessage.ct;
    }

    static decryptMe = (password: string, encMessage: string) => {
        //Decrypt
        var data = { "v": 1, "mode": "ccm", "adata": "", "cipher": "aes", "ct": encMessage };
        var frmtData = JSON.stringify(data);
        var parsedMessage = JSON.parse(frmtData);
        jQuery.extend(parsedMessage, this.options);
        var messageWithParameters = JSON.stringify(parsedMessage);
        return sjcl.decrypt(password, messageWithParameters);
    }

    static encrypt = (passphrase: string, plaintext: string) => {
        return this.encryptMe(passphrase, plaintext);
    }

    static decrypt = (passphrase: string, encText: string) => {
        return this.decryptMe(passphrase, encText);
    }

    static encryptDefault = (default_key: string, plaintext: string) => {
        return this.encryptMe(default_key, plaintext);;
    }

    static decryptDefault = (default_key: string, encText: string) => {
        return this.decryptMe(default_key, encText);
    }

}
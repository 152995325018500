import { Injectable } from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';
import { User } from '../data/user';
import { Connection } from '../data/Connection';
import { CommunicationKey } from '../data/CommunicationKey';

@Injectable()
export class AppDataService {

  @LocalStorage()
  public darkModeEnabled: boolean;

  @LocalStorage()
  public loggedInUser: User;

  @LocalStorage()
  public hiddinToken: string;

  @LocalStorage()
  public hiddinBogusKey: string;

  @LocalStorage()
  public hiddinSignUpTime: string;

  @LocalStorage()
  public chatMembers: Connection[];

  @LocalStorage()
  public commKey: CommunicationKey;

  public clearData() {
    this.loggedInUser = null as any;
    this.hiddinToken = null as any;
    this.hiddinBogusKey = null as any;
    this.hiddinSignUpTime = null as any;
    this.chatMembers = null as any;
    this.commKey = null as any;
  }

}
<div class="main-wrapper mainBg">
    <div class="container-fluid">
      <div class="frame card-body2">
        <a [routerLink]="['/home']" class="navbar-brand logo logoPostion">
          <img src="assets/images/logo-white.png" class="img-fluid logo-bg" alt="Logo">
        </a>
        <h4 class="mt-4 text-white">Login</h4>
        <form [formGroup]="loginFormGroup" (ngSubmit)="doLogin()" class="form-signin mt-4">
          <div class="form-group">
            <label class="text-white">Email*</label>
            <input type="email" formControlName="emailAddress" class="form-control" [(ngModel)]="loginRequest['email']" [ngClass]="{ 'is-invalid': (submitted || f['emailAddress'].touched) && f['emailAddress'].errors }" placeholder="Your Email" />
            <div *ngIf="(submitted || f['emailAddress'].touched) && f['emailAddress'].errors" class="invalid-feedback">
              <div class="text-white" *ngIf="f['emailAddress'].errors['required']">Email is required</div>
              <div class="text-white" *ngIf="f['emailAddress'].errors['email'] || f['emailAddress'].errors['pattern']">Email must be a valid email address</div>
            </div>
          </div>
          <div class="form-group">
            <label class="text-white">Password*</label>
            <input type="password" formControlName="userPassword" class="form-control pass-input" [(ngModel)]="loginRequest['password']" [ngClass]="{ 'passwordInvalid': (submitted || f['userPassword'].touched) && f['userPassword'].errors }" placeholder="Your Password" id="loginPwd" onPaste="return false" />
            <span [ngClass]="passwordVisible ? 'feather-eye-off' : 'feather-eye'" (click)="togglePassword('loginPwd')" class="profile-views toggle-password"></span>
            <div *ngIf="(submitted || f['userPassword'].touched) && f['userPassword'].errors" class="invalid-feedback">
              <div class="text-white" *ngIf="f['userPassword'].errors['required']">Password is required</div>
              <div  class="text-white" *ngIf="f['userPassword'].errors['minlength']">Password must be at least 6 characters </div>
            </div>
          </div>
          <div class="form-group error-msg text-center text-white" *ngIf="errorMessage && errorMessage.length > 0">
            {{errorMessage}}
          </div>
          <div class="d-grid">
            <button class="btn-signin mt-2  mb-4" [class.disabled]="isLoginInProgress" type="submit">
              <div  class="text-white" *ngIf="isLoginInProgress">Please wait...</div>
              <div  class="text-white" *ngIf="!isLoginInProgress">Sign In</div>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <loader [message]="loaderMessage"></loader>
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoginRequest } from 'src/app/data/loginrequest';
import { AppService } from 'src/app/service/app.service';
import { AppDataService } from 'src/app/service/appdata.service';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginRequest: LoginRequest = new LoginRequest();
  loginFormGroup: FormGroup;
  loginForm: any;
  submitted: boolean = false;
  errorMessage: any;
  isLoginInProgress: boolean = false;

  emailPattern = '^[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}';
  passwordVisible: boolean = false;

  redirectUrl: any = '';
  loaderMessage = '';
  token: any;

  constructor(private router: Router,
    private appService: AppService,
    private appDataService: AppDataService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private loader: NgxSpinnerService,
    private globals: Globals) {
    this.readUrlParams();
    this.checkForAutoLogin();
  }

  ngOnInit() {
    this.loginFormGroup = this.formBuilder.group({
      emailAddress: ['', [Validators.required, Validators.maxLength(50), Validators.pattern(this.emailPattern)]],
      userPassword: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  readUrlParams() {
    this.route.queryParamMap.subscribe(params => {
      this.redirectUrl = params.get('redirectUrl');
      this.errorMessage = params.get('errorMessage');
      this.token = params.get('token');
      this.loader.hide();
    });
  }

  checkForAutoLogin() {
    if (!this.globals.isEmpty(this.token)) {
      this.doAutoLogin();
    } else if (!this.globals.isEmpty(this.appDataService.hiddinToken)) {
      this.navigateToPage();
    }
  }

  doAutoLogin() {
    this.loaderMessage = "Auto Logging in...";
    this.loader.show();
    this.appService.autoLogin(this.token)
      .subscribe({
        next: (response) => {
          this.loaderMessage = "";
          this.loader.hide();
          if (response.success) {
            response.data = this.globals.decryptDefault(response.data);
            this.appDataService.hiddinToken = response.data.access_token;
            this.appDataService.hiddinBogusKey = response.data.key;
            this.appDataService.loggedInUser = response.data.user;

            this.syncDataFromHiddin();
          } else {
            this.errorMessage = response.message;
          }
        },
        error: (error) => this.handleError(error)
      });
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginFormGroup.controls; }

  ionViewDidEnter() {
    this.resetForm();
  }

  resetForm = () => {
    this.loginForm.reset();
    this.loginRequest = new LoginRequest();
    this.submitted = false;
    this.errorMessage = null;
  }

  togglePassword = (id: string) => {
    const password = document.querySelector('#' + id) as HTMLElement;
    const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
    password.setAttribute('type', type);

    this.passwordVisible = !this.passwordVisible;
  }

  doLogin = () => {
    this.submitted = true;
    this.errorMessage = null;

    // stop here if form is invalid
    if (this.loginFormGroup.invalid) {
      return;
    }
    this.isLoginInProgress = true;
    this.loaderMessage = "Logging in...";
    this.loader.show();
    this.appService.userHiddinLogin({ 'email': this.loginRequest.email, 'password': this.loginRequest.password, 'token': '' })
      .subscribe({
        next: (response) => {
          this.loaderMessage = "";
          this.loader.hide();
          if (response.success) {
            response.data = this.globals.decryptDefault(response.data);
            this.appDataService.hiddinToken = response.data.access_token;
            this.appDataService.hiddinBogusKey = response.data.key;
            this.appDataService.loggedInUser = response.data.user;

            this.syncDataFromHiddin();
          } else {
            this.isLoginInProgress = false;
            this.errorMessage = response.message;
          }
        },
        error: (error) => this.handleError(error)
      });
  }

  syncDataFromHiddin = () => {
    this.loaderMessage = "Please wait...";
    this.loader.show();
    this.appService.syncDataFromHiddin().subscribe({
      next: (response) => {
        this.isLoginInProgress = false;
        this.loaderMessage = "";
        this.loader.hide();
        if (response.success) {
          this.navigateToPage();
        } else {
          this.appDataService.clearData();
          this.errorMessage = response.message;
        }
      },
      error: (error) => this.handleError(error)
    });
  }

  handleError = (error: any) => {
    console.log(error);
    this.isLoginInProgress = false;
    this.loader.hide();
  }

  navigateToPage() {
    if (!this.globals.isEmpty(this.redirectUrl)) {
      window.location.href = this.redirectUrl;
    } else {
      this.router.navigate(['/chat', 0]);
    }
  }
}

export class Connection {
  id?: string;
  userId: number;
  firstName: string;
  lastName?: string;
  userName?: string;
  img?: string;
  unseenMsgCount?: number;
  online?: boolean;
  lastSeenTime?: string;
  lastMessage?: string;
  lastMessageTime?: any;
  isTyping?: boolean;
  chatInitiated?: boolean;
  city?: string;
  state?: string;
  country?: string;
  status?: string;
  startTime?: string;
  endTime?: string;
  prefTime?: any;
  timeZone: string;
  dnd?:boolean;
}
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppService } from './service/app.service';
import { XHRHandler } from './service/xhrhandler.service';
import { AppDataService } from './service/appdata.service';
import { WebSocketService } from './service/websocket.service';
import { CustomDatePipe } from './pipe/CustomDatePipe';
import { MessageService } from './service/message.service';
import { Time12FormatPipe } from './pipe/time-12-format-pipe';
import { AuthService } from './service/auth.service';
import { Globals } from './globals';
import { AuthInterceptor } from './interceptor/auth.interceptor';
import { LoggingInterceptor } from './interceptor/logging.interceptor';
import { ConnectionSearchFilter } from './pipe/ConnectionSearchFilter';
import { ChatMemberSearchFilter } from './pipe/ChatMemberSearchFilter';
import { HighlightDirective } from './directives/highlight.directive';
import { BackButtonDirective } from './directives/back-button.directive';
import { NavigationService } from './service/navigation.service';
import { NgxSpinnerModule } from "ngx-spinner";
import { ChatSidebarMenuComponent } from './pages/common/chat-sidebar-menu/chat-sidebar-menu.component';
import { HeaderComponent } from './pages/common/header/header.component';
import { FooterComponent } from './pages/common/footer/footer.component';
import { LoaderComponent } from './pages/common/loader/loader.component';
import { ChatComponent } from './pages/chat/chat.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { ConnectionComponent } from './pages/connection/connection.component';
import { LastSeenTimePipe } from './pipe/last-seen-time.pipe';
import { CommunicationService } from './service/communication.service';
import { ModalService } from './service/modal.service';
import { ModalComponent } from './modal/modal.component';
import { VideoPlayComponent } from './modal/video-play/video-play.component';
import { IonicModule } from '@ionic/angular';
import { DataService } from './service/data.service';

@NgModule({
  declarations: [
    AppComponent,
    CustomDatePipe,
    Time12FormatPipe,
    ConnectionSearchFilter,
    ChatMemberSearchFilter,
    HighlightDirective,
    BackButtonDirective,
    ChatSidebarMenuComponent,
    HeaderComponent,
    FooterComponent,
    LoaderComponent,
    ChatComponent,
    HomeComponent,
    LoginComponent,
    ConnectionComponent,
    LastSeenTimePipe,
    ModalComponent,
    VideoPlayComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    IonicModule,
    NgxWebstorageModule.forRoot()
  ],
  providers: [AppService,
    MessageService,
    XHRHandler,
    AppDataService,
    WebSocketService,
    AuthService,
    CommunicationService,
    Globals,
    ModalService,
    NavigationService,
    DataService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoggingInterceptor,
      multi: true,
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }

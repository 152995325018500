import {
  Component, ElementRef, HostListener, ViewChild, EventEmitter
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { NgxSpinnerService } from 'ngx-spinner';
import { WebsocketEvents } from 'src/app/constants/WebsocketEvents';
import { CommunicationKey } from 'src/app/data/CommunicationKey';
import { Connection } from 'src/app/data/Connection';
import { Message } from 'src/app/data/message';
import { User } from 'src/app/data/user';
import { Globals } from 'src/app/globals';
import { AppService } from 'src/app/service/app.service';
import { AppDataService } from 'src/app/service/appdata.service';
import { CommunicationService } from 'src/app/service/communication.service';
import { MessageService } from 'src/app/service/message.service';
import { NavigationService } from 'src/app/service/navigation.service';
import { WebSocketService } from 'src/app/service/websocket.service';
import { EncryptionUtil } from 'src/app/utils/EncryptionUtil';
import { isEmpty, messageKey, nonEmpty, getFullName } from 'src/app/utils/CommonUtil';
import { TimeZoneUtils } from 'src/app/utils/TimeZoneUtils';
import { ConnectionType } from 'src/app/constants/ConnectionType';
import { AppConfig } from 'src/app/data/AppConfig';

@Component({
  selector: 'chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css'],
})
export class ChatComponent {
  @ViewChild('messageAutoScroll', { read: ElementRef }) public messageAutoScroll: ElementRef<any>;
  hideLeftSideBar: boolean;

  status: string;

  // Tab selected; chat or connection
  selectedTab: string;
  // Search Term for chat members
  searchTerm: string = '';
  searchInMessage: string = '';

  // Logged In user
  loggedInUser: User;
  // Selected user in chat members
  selectedUser: Connection;
  // Any error occured
  errorMessage: string = '';

  // List of chat members
  chatMembers: Connection[] = [];

  // Text message to be send
  message: string = '';
  // UserId vs Messages
  userIdVsMessages = new Map();
  // Selected user messages, used for rendered
  publishedMessage: Message[] = new Array();

  goToBottom: boolean = true;

  // Pagination to fetch user messages
  page: Number = 0;
  size: Number = 10;
  paginationData: Map<number, any> = new Map();

  loaderMessage = '';
  chatLoading = false;
  moreChatLoading = false;

  commIdVsAesKey: Map<any, any> = new Map();

  appConfig: AppConfig;

  parentEmitter = new EventEmitter<Message>();

  constructor(private appDataService: AppDataService,
    private websocketService: WebSocketService,
    private appService: AppService,
    private messageService: MessageService,
    private communicationService: CommunicationService,
    private router: Router,
    private loader: NgxSpinnerService,
    public navigation: NavigationService,
    private route: ActivatedRoute,
    //private janus: JanusService,
    private globals: Globals) {
    // Default user need to be selected
    let selectedUserId = this.route.snapshot.paramMap.get('selectedUser');

    // Default page data
    this.selectedTab = 'chat';

    // Loggedin user from cache
    this.loggedInUser = appDataService.loggedInUser;
    if (isEmpty(this.loggedInUser))
      this.router.navigate(['/login']);

    this.loadAppPropConfig();
    this.loadChatMembers(selectedUserId);

    // Fetch communication key to encrypt messages
    if (isEmpty(this.appDataService.commKey) || isEmpty(this.appDataService.commKey.aesKey))
      this.getCommunicationKey();
    else {
      if (nonEmpty(this.appDataService.commKey) && nonEmpty(this.appDataService.commKey.commIdVsAesKey))
        this.commIdVsAesKey = new Map(JSON.parse(this.appDataService.commKey.commIdVsAesKey));
      // Initialize websocket
      this.initializeWebsocket();
    }
  }

  ngAfterViewChecked() {
    if (nonEmpty(this.publishedMessage) && this.goToBottom)
      this.scrollToBottom()
  }

  private loadAppPropConfig() {
    this.appService.loadAppConfig().subscribe({
      next: (response) => {
        if (response.success) {
          this.appConfig = this.globals.decryptDefault(response.data);
        } else {
          console.log("Error occured while loading app config.");
        }
      },
      error: (error) => { this.handleError(error) }
    });
  }

  async ngOnDestroy() {
    //this.janus.shutdown();
  }

  // Message level security
  getCommunicationKey() {
    this.communicationService.fetchCommunicationKey().subscribe({
      next: (response) => {
        if (response && response.success) {
          response.data = this.globals.decrypt(response.data);
          // console.log("Comm Key: ", response.data);
          this.appDataService.commKey = new CommunicationKey(response.data.communicationId, response.data.key, '');

          // Initialize websocket
          this.initializeWebsocket();
        }
      },
      error: (error) => { this.handleError(error) }
    });
  }

  getCommaSepCommIds(messages: Message[]) {
    let ids = "";
    if (isEmpty(messages) || messages.length == 0)
      return ids;
    messages.forEach(message => {
      if (!this.commIdVsAesKey.has(message.communicationId)) {
        ids += message.communicationId + ",";
      }
    });
    if (nonEmpty(ids))
      ids.substring(0, ids.length - 1);
    return ids;
  }

  decryptMessages(messages: Message[]) {
    let commIds = this.getCommaSepCommIds(messages);

    if (nonEmpty(commIds)) {
      this.communicationService.fetchAesKeysForCommIds(commIds)
        .subscribe({
          next: (response) => {
            if (response && response.success) {
              let aesData = this.globals.decrypt(response.data);
              // console.log("AesData: ", aesData);
              // aesData.commIdVsKey.com.forEach((commId: string, aesKey: string) => {
              //   this.appDataService.commKey.commIdVsAesKey.set(commId, aesKey);
              // });
              Object.keys(aesData.commIdVsKey).forEach(commId => {
                this.commIdVsAesKey.set(commId, aesData.commIdVsKey[commId]);
              });
              this.appDataService.commKey.commIdVsAesKey = JSON.stringify(Array.from(this.commIdVsAesKey.entries()));
              this.appDataService.commKey = new CommunicationKey(this.appDataService.commKey.communicationId, this.appDataService.commKey.aesKey, this.appDataService.commKey.commIdVsAesKey);
              // console.log("Aes Keys: ", this.appDataService.commKey.commIdVsAesKey);

              // now decrypt messages
              this.decryptMessagesByAesKeys(messages);
            }
          },
          error: (error) => { this.handleError(error) }
        });
    } else {
      this.decryptMessagesByAesKeys(messages);
    }
  }

  decryptMessagesByAesKeys(messages: Message[]) {
    messages.forEach(message => this.decryptSingleMessageByAesKey(message));
  }

  decryptSingleMessage(message: Message) {
    if (!this.commIdVsAesKey.has(message.communicationId)) {
      this.communicationService.fetchAesKeysForCommIds(message.communicationId)
        .subscribe({
          next: (response) => {
            if (response && response.success) {
              let aesData = this.globals.decrypt(response.data);
              // console.log("commIdVsAesKeys: ", aesData);
              Object.keys(aesData.commIdVsKey).forEach(commId => {
                this.commIdVsAesKey.set(commId, aesData.commIdVsKey[commId]);
              });
              this.appDataService.commKey.commIdVsAesKey = JSON.stringify(Array.from(this.commIdVsAesKey.entries()));
              this.appDataService.commKey = new CommunicationKey(this.appDataService.commKey.communicationId, this.appDataService.commKey.aesKey, this.appDataService.commKey.commIdVsAesKey);

              // now decrypt messages
              this.decryptSingleMessageByAesKey(message);
            }
          },
          error: (error) => { this.handleError(error) }
        });
    } else {
      this.decryptSingleMessageByAesKey(message);
    }
  }

  decryptSingleMessageByAesKey(message: Message) {
    message.message = EncryptionUtil.decrypt(this.commIdVsAesKey.get(message.communicationId), message.message as string);
    message.decrypted = true;
    // update message in UI
    this.publishedMessage = this.getMessages(this.selectedUser.userId);
    this.setLastMessage();
  }

  // Websocket operations
  initializeWebsocket = () => {
    // Connect websocket
    this.websocketService.initiate();
    this.websocketService.openConnection(this.openConnectionCallback);
    this.websocketService.startListening(this.startListeningCallback);
    // console.log("Websocket initialized");
  }

  openConnectionCallback = () => {
    let message: Message = {
      type: ConnectionType.CHAT,
      subType: WebsocketEvents.JOINED,
      from: this.loggedInUser.id,
      to: this.selectedUser?.userId,
      fromUserName: getFullName(this.loggedInUser),
      toUserName: getFullName(this.selectedUser),
      message: null,
      communicationId: this.appDataService.commKey.communicationId,
      creationTime: moment.utc().format()
    }
    this.websocketService.sendMessage(message);
  }

  startListeningCallback = (event: MessageEvent) => {
    let message: Message = this.globals.decryptDefault(event.data);
    // let message: Message = JSON.parse(event.data);
    if (isEmpty(message))
      return;

    if (message.type == ConnectionType.CHAT) {
      this.handleChatMessages(message);
    } else if (message.type == ConnectionType.VIDEO) {
      console.log("Received message", message);
      this.parentEmitter.emit(message);
    } else {
      console.log("Invalid message: ", message);
    }
  }

  isWebSocketOpen = () => { return this.websocketService.isWebSocketOpen() }
  isWebSocketConnecting = () => { return this.websocketService.isWebSocketConnecting() }
  isWebSocketClosed = () => { return this.websocketService.isWebSocketClosed() }

  reconnect() {
    this.initializeWebsocket();
  }

  handleChatMessages(message: Message) {
    if (message.subType == WebsocketEvents.MESSAGE) {
      message.decrypted = false;
      this.publishedMessage = this.saveAndGetMessages(message);
      // decrypt message
      this.decryptSingleMessage(message);
      // Set last message to display in listing
      this.setLastMessage();
      // set unread message count for user
      this.setUnreadMsgCount(message);
      this.markOnlineIfNot(message);
      // this.publishedMessage.sort((a: any, b: any) => (a.creationTime > b.creationTime) ? 1 : -1);
    } else if (message.subType == WebsocketEvents.TYPING) {
      if (message.from != this.loggedInUser.id) {
        this.showUserTypingIndicator(message);
      }
    } else if (message.subType == WebsocketEvents.JOINED) {
      this.setUserStatus(message, true);
    } else if (message.subType == WebsocketEvents.LEFT) {
      this.setUserStatus(message, false);
    }
  }

  createMessage = () => {
    let msg = this.message;
    if (msg == '' || msg == undefined) return;

    let message: Message = {
      type: ConnectionType.CHAT,
      subType: WebsocketEvents.MESSAGE,
      from: this.loggedInUser.id,
      to: this.selectedUser.userId,
      fromUserName: getFullName(this.loggedInUser),
      toUserName: getFullName(this.selectedUser),
      message: msg,
      communicationId: this.appDataService.commKey.communicationId,
      creationTime: moment.utc().format(),
      decrypted: true
    }

    this.publishedMessage = this.saveAndGetMessages(message);
    this.message = '';

    this.goToBottom = true;

    this.websocketService.sendMessage(message);
  }

  setUserStatus(message: Message, isOnline: boolean) {
    let connection: Connection = this.chatMembers.find(u => u.userId == message.from) as Connection;
    if (nonEmpty(connection)) {
      connection.online = isOnline;
      connection.lastSeenTime = message.lastSeenTime;
      // update data in cache
      this.updateConnectionInCache(connection);
    }
  }

  markOnlineIfNot(message: Message) {
    let connection: Connection = this.chatMembers.find(u => u.userId == message.from) as Connection;
    if (nonEmpty(connection) && !connection.online) {
      connection.online = true;
      connection.lastSeenTime = moment.utc().format();
      // update data in cache
      this.updateConnectionInCache(connection);
    }
  }

  updateConnectionInCache(connection: Connection) {
    let existingData = this.appDataService.chatMembers;
    for (const index in existingData) {
      if (existingData[index].userId == connection.userId) {
        existingData[index] = connection;
        this.appDataService.chatMembers = existingData;
        break;
      }
    }
  }

  sendTypeIndicator = () => {
    let message: Message = {
      type: ConnectionType.CHAT,
      subType: WebsocketEvents.TYPING,
      from: this.loggedInUser.id,
      to: this.selectedUser.userId,
      fromUserName: getFullName(this.loggedInUser),
      toUserName: getFullName(this.selectedUser),
      message: null,
      communicationId: this.appDataService.commKey.communicationId,
      creationTime: moment.utc().format()
    }
    this.websocketService.sendMessage(message);
  }

  showUserTypingIndicator(message: Message) {
    this.chatMembers.filter(u => u.userId == message.from).map(u => u.isTyping = true);
    setTimeout(() => {
      this.chatMembers.filter(u => u.userId == message.from).map(u => u.isTyping = false);
    }, 2000);
  }

  @HostListener('window:beforeunload')
  close = () => {
    let message: Message = {
      type: ConnectionType.CHAT,
      subType: WebsocketEvents.LEFT,
      from: this.loggedInUser.id,
      to: this.selectedUser.userId,
      fromUserName: getFullName(this.loggedInUser),
      toUserName: getFullName(this.selectedUser),
      message: null,
      communicationId: this.appDataService.commKey.communicationId,
      creationTime: moment.utc().format()
    }
    this.websocketService.sendMessage(message);
  }

  loadChatMembers = (selectedUserId: any) => {
    this.loaderMessage = "Loading connections...";
    this.loader.show();
    this.appService.loadConnection().subscribe({
      next: (response) => {
        this.loader.hide();
        if (response.success) {
          let page = this.globals.decrypt(response.data);
          if (nonEmpty(page)) {
            this.chatMembers = page.content;
            this.appDataService.chatMembers = this.chatMembers;
            if (nonEmpty(this.chatMembers)) {
              this.defaultOperations(selectedUserId);
            }
          }
        } else {
          console.log("Error occured while loading chat members.");
        }
      },
      error: (error) => { this.handleError(error) }
    });
  }

  defaultOperations(selectedUserId: any) {
    this.filterChatMember();
    // Check and set 'Do Not Disturb' based on user's preferred time
    this.checkAndSetForDnd();
    // Select first user as default selected
    this.defaultSelectedUser(selectedUserId);
  }

  filterChatMember() {
    this.chatMembers = this.chatMembers.filter(u => u.userId != this.loggedInUser.id && u.chatInitiated == true);
  }

  checkAndSetForDnd() {
    this.chatMembers.forEach(connection => {
      this.checkForDND(connection);
    });
  }

  checkForDND(connection: Connection) {
    // For preferred Time
    if (nonEmpty(connection.prefTime)) {
      if (connection.prefTime == "All") {
        connection.dnd = false;
        return;
      } else {
        let timeRanges = connection.prefTime.split(",");
        for (var range of timeRanges) {
          this.checkForOneTimeRange(range, connection);
        }
      }
    }

    // For Start and End time
    if (nonEmpty(connection.startTime)) {
      this.checkForOneTimeRange(connection.startTime + "-" + connection.endTime, connection);
    }

  }

  checkForOneTimeRange(timeRange: any, connection: Connection) {
    let now = moment().tz(this.loggedInUser.timeZone);

    let oneTimeRange = timeRange.split("-");
    let utcStartTime = TimeZoneUtils.convertOnlyTimeToUTC(oneTimeRange[0], connection.timeZone);
    let utcEndTime = TimeZoneUtils.convertOnlyTimeToUTC(oneTimeRange[1], connection.timeZone);

    let startDate = TimeZoneUtils.convertUTCToCurrentTimeMoment(utcStartTime, this.loggedInUser.timeZone);
    let endDate = TimeZoneUtils.convertUTCToCurrentTimeMoment(utcEndTime, this.loggedInUser.timeZone);

    // console.log("StartTime: ", oneTimeRange[0], " " + connection.timeZone);
    // console.log("utcStartTime: ", utcStartTime);
    // console.log("StartTime: ", startDate, " " + this.loggedInUser.timeZone);

    // console.log("EndTime: ", oneTimeRange[1], " " + connection.timeZone);
    // console.log("utcEndTime: ", utcEndTime);
    // console.log("EndTime: ", endDate, " " + this.loggedInUser.timeZone);

    let valid = now.isAfter(startDate) && now.isBefore(endDate);
    if (valid) {
      connection.dnd = true;
      return;
    }
  }

  defaultSelectedUser(selectedUserId: any) {
    if (nonEmpty(this.chatMembers)) {
      if (nonEmpty(selectedUserId)) {
        let selUser = this.chatMembers.filter(u => u.userId == Number(selectedUserId) && u.chatInitiated == true);
        if (nonEmpty(selUser)) {
          this.setSelectedUser(selUser[0]);
        } else {
          this.setSelectedUser(this.chatMembers[0]);
        }
      } else {
        this.setSelectedUser(this.chatMembers[0]);
      }
    }
  }

  // Message level operations
  // Add message to specific user
  addMessage(message: Message) {
    let receiverId = message.from === this.loggedInUser.id ? message.to : message.from;
    let msgKey = messageKey(this.loggedInUser.id, receiverId);
    let messages = this.userIdVsMessages.get(msgKey);
    if (isEmpty(messages))
      messages = [];
    messages.push(message);
    this.userIdVsMessages.set(msgKey, messages);
  }

  loadUserChat(senderId: Number, receiverId: Number, page: Number, size: Number, isLoadingMore: boolean) {
    this.errorMessage = '';
    // this.loaderMessage = "Loading chat...";
    // this.loader.show();
    if (isLoadingMore) {
      this.moreChatLoading = true;
    } else {
      this.chatLoading = true;
    }

    this.messageService.loadUserChat(senderId, receiverId, page, size)
      .subscribe({
        next: (response) => {
          if (isLoadingMore) {
            this.moreChatLoading = false;
          } else {
            this.chatLoading = false;
          }
          // this.loader.hide();
          if (response.success) {
            if (!isEmpty(response.data.content)) {
              response.data.content.sort((a: any, b: any) => (a.creationTime > b.creationTime) ? 1 : -1).map((msg: any) => msg.decrypted = false);

              this.decryptMessages(response.data.content);

              // Appending items in front
              this.publishedMessage = this.saveAndGetBulkMessages(response.data.content);
              this.publishedMessage.sort((a: any, b: any) => (a.creationTime > b.creationTime) ? 1 : -1);

              // Set pagination data
              response.data.content = undefined;
              this.paginationData.set(this.selectedUser.userId, response.data);

              if (!isLoadingMore)
                this.setLastMessage();
            }
            // console.log(this.publishedMessage);
          } else {
            this.errorMessage = response.message;
          }
        },
        error: (error) => {
          if (isLoadingMore) {
            this.moreChatLoading = false;
          } else {
            this.chatLoading = false;
          }
          this.handleError(error)
        },
      });
  }

  isMoreMessages = () => {
    let pageable = this.paginationData.get(this.selectedUser.userId);
    if (isEmpty(pageable))
      return false;
    return pageable.totalPages > (pageable.number + 1);
  }

  loadMoreMessages = () => {
    this.goToBottom = false;
    let pageable = this.paginationData.get(this.selectedUser.userId);
    this.loadUserChat(this.loggedInUser.id, this.selectedUser.userId, pageable.number + 1, this.size, true);
  }

  getMessages(selectedUserId: Number) {
    let msgKey = messageKey(this.loggedInUser.id, selectedUserId);
    return this.userIdVsMessages.get(msgKey);
  }

  saveAndGetMessages(message: Message) {
    this.addMessage(message);
    // Get message for selected used from map
    return this.getMessages(this.selectedUser.userId);
  }

  saveAndGetBulkMessages(messages: Message[]) {
    for (let msg of messages) {
      this.addMessage(msg);
    }
    return this.getMessages(this.selectedUser.userId);
  }

  copyMessage(message: string) {
    console.log("Copy message");
  }

  deleteMessage(message: Message) {
    console.log("Delete message");
  }

  editMessage(message: Message) {
    console.log("Edit message");
  }

  setUnreadMsgCount(message: Message) {
    this.chatMembers.filter(member => member.userId == message.from && message.from != this.selectedUser.userId)
      .map(member => {
        if (member.unseenMsgCount != undefined) {
          member.unseenMsgCount += 1;
        } else {
          member.unseenMsgCount = 1;
        }
        member.lastMessageTime = message.creationTime;
        return member;
      });
  }

  resetMsgUnreadCount(user: Connection) {
    user.unseenMsgCount = undefined;
  }

  resetMsgTxt = () => {
    this.searchInMessage = '';
  }

  setSelectedUser(user: Connection) {
    this.hideLeftSideBar = true;
    this.selectedUser = user;
    // console.log("Selected user: " + getFullName(this.selectedUser));
    this.resetPagination();
    this.publishedMessage = this.getMessages(this.selectedUser.userId);
    if (isEmpty(this.publishedMessage)) {
      this.loadUserChat(this.loggedInUser.id, this.selectedUser.userId, this.page, this.size, false);
    } else {
      this.publishedMessage.sort((a: any, b: any) => (a.creationTime > b.creationTime) ? 1 : -1);
    }
    this.goToBottom = true;
    this.setLastMessage();
    this.resetMsgUnreadCount(user);
    // console.log("Messages: ", this.publishedMessage);
  }

  // Pagination operations
  resetPagination = () => {
    this.page = 0;
    this.size = 10;
  }

  backToChatMember = () => {
    this.hideLeftSideBar = false;
  }

  // Local utility methods
  isEmpty(data: any) {
    return isEmpty(data);
  }

  nonEmpty(data: any) {
    return nonEmpty(data);
  }

  getFirstLastLetter(connection: Connection) {
    return connection.firstName.charAt(0) + connection.lastName?.charAt(0);
  }

  getFirstLastLetterFromFullName(fullName: string) {
    if (nonEmpty(fullName)) {
      let splitArray = fullName.split(" ");
      if (splitArray.length == 1) {
        return splitArray[0].charAt(0);
      } else if (splitArray.length > 1) {
        return splitArray[0].charAt(0) + splitArray[1].charAt(0);
      }
    }
    return '';
  }

  goToConnection = () => {
    this.router.navigate(["/connection"]);
  }

  scrollToBottom = () => {
    if (nonEmpty(this.messageAutoScroll))
      this.messageAutoScroll.nativeElement.scrollTop = this.messageAutoScroll.nativeElement.scrollHeight;

  }

  handleError(error: any) {
    console.log(error);
    this.loader.hide();
  }

  setLastMessage() {
    this.chatMembers.forEach(member => {
      let messages = this.getMessages(member.userId);
      if (nonEmpty(messages)) {
        let lastMessage = messages[messages.length - 1];
        if (this.isMessageDecrypted(lastMessage))
          member.lastMessage = lastMessage.message as string;
        else
          member.lastMessage = "Decrypting content...";
      }
    });

  }

  logoutEvent(logout: boolean) {
    if (logout) {
      this.close();
    }
  }

  lastSeenTime(value: any) {
    return moment(value).tz(this.loggedInUser.timeZone).format("hh:mm A");
  }

  isMessageDecrypted(message: Message) {
    return message.decrypted != undefined && message.decrypted;
  }

  getSearchElement = () => {
    return document.getElementById("chat-search") as HTMLElement;
  }

  showSearchBox = () => {
    this.getSearchElement().classList.add("visible-chat");
  }

  hideSearchBox = () => {
    this.getSearchElement().classList.remove("visible-chat");
  }

  initiateAudioCall = () => {
    let message: Message = {
      type: ConnectionType.INITIATE_AUDIO,
      subType: "",
      from: this.loggedInUser.id,
      to: this.selectedUser.userId,
      fromUserName: getFullName(this.loggedInUser),
      toUserName: getFullName(this.selectedUser),
      message: '',
      communicationId: this.appDataService.commKey.communicationId,
      creationTime: moment.utc().format(),
      decrypted: true
    }
    this.parentEmitter.emit(message);
  }

  initiateVideoCall = () => {
    let message: Message = {
      type: ConnectionType.INITIATE_VIDEO,
      subType: "",
      from: this.loggedInUser.id,
      to: this.selectedUser.userId,
      fromUserName: getFullName(this.loggedInUser),
      toUserName: getFullName(this.selectedUser),
      message: '',
      communicationId: this.appDataService.commKey.communicationId,
      creationTime: moment.utc().format(),
      decrypted: true
    }
    this.parentEmitter.emit(message);
  }
}
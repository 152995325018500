import {
  Component, EventEmitter,
  Input, OnChanges,
  Output, SimpleChanges
} from '@angular/core';
import { User } from 'src/app/data/user';
import { Router } from '@angular/router';
import { AppDataService } from 'src/app/service/appdata.service';
import { isEmpty, nonEmpty } from 'src/app/utils/CommonUtil';

@Component({
  selector: 'chat-sidebar-menu',
  templateUrl: './chat-sidebar-menu.component.html',
  styleUrls: ['./chat-sidebar-menu.component.css']
})
export class ChatSidebarMenuComponent implements OnChanges {
  @Input()
  selectedTab: string = '';
  @Input()
  loggedInUser: User = new User();

  @Output()
  outputMessage = new EventEmitter<boolean>();

  constructor(private appDataService: AppDataService,
    private router: Router) {
    this.darkMode();
  }

  changeTab(selectedTab: string, url: string) {
    this.selectedTab = selectedTab;
    // this.router.navigateByUrl(url);
  }

  logout() {
    this.outputMessage.emit(true);
    this.appDataService.clearData();
    this.router.navigateByUrl("/home");
  }

  getFirstLastLetter() {
    return this.loggedInUser.firstName.charAt(0) + this.loggedInUser.lastName?.charAt(0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const chng = changes['selectedTab'];
    if (isEmpty(chng.currentValue))
      return;
    // let message: Message = JSON.parse(chng.currentValue);
    // if (message.type == WebsocketEvents.JOINED) {
    //   this.setUserStatus(message, true);
    // } else if (message.type == WebsocketEvents.LEFT) {
    //   this.setUserStatus(message, false);
    // }
  }

  darkMode() {
    if (this.appDataService.darkModeEnabled != undefined) {
      if (this.appDataService.darkModeEnabled)
        this.enableDarkMode();
      else
        this.disableDarkMode();
    }
  }

  changeDarkMode() {
    if (this.appDataService.darkModeEnabled != undefined) {
      if (this.appDataService.darkModeEnabled)
        this.disableDarkMode();
      else
        this.enableDarkMode();
    } else {
      this.enableDarkMode();
    }
  }

  enableDarkMode() {
    const bodyTag = document.body;
    bodyTag.classList.add('darkmode');
    this.appDataService.darkModeEnabled = true;
  }

  disableDarkMode() {
    const bodyTag = document.body;
    bodyTag.classList.remove('darkmode');
    this.appDataService.darkModeEnabled = false;
  }
}

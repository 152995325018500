export class WebsocketEvents {
    // Chat events
    public static JOINED: string = "JOINED";
    public static LEFT: string = "LEFT";
    public static TYPING: string = "TYPING";
    public static MESSAGE: string = "MESSAGE";
    // Video call events
    public static INITIATE: string = "INITIATE";
    public static PUBLISH: string = "PUBLISH";
    public static TERMINATE: string = "TERMINATE";
    public static ACCEPTED: string = "ACCEPTED";
    public static REJECTED: string = "REJECTED";
    public static OFFLINE: string = "OFFLINE";
}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonHeader } from './header.service';
import { environment } from 'src/environments/environment';
import { AppDataService } from './appdata.service';

const SERVER_BASE_URL = environment.SERVER_BASE_URL;

@Injectable()
export class XHRHandler {

  constructor(private httpClient: HttpClient, private appData: AppDataService) { }

  doGet(path: string) {
    return this.httpClient.get(SERVER_BASE_URL + path);
  }

  doGetWithAutHeader(path: string) {
    return this.httpClient.get(SERVER_BASE_URL + path, CommonHeader.getAuthHeaders(this.appData.hiddinToken));
  }

  doPost(path: string, reqData: any) {
    return this.httpClient.post(SERVER_BASE_URL + path, JSON.stringify(reqData), { headers: CommonHeader.getCommonHeaders() });
  }

  doPostWithAuthHeader(path: string, reqData: any) {
    return this.httpClient.post(SERVER_BASE_URL + path, JSON.stringify(reqData), CommonHeader.getAuthHeaders(this.appData.hiddinToken));
  }
}
import { Pipe, PipeTransform } from '@angular/core';
import { time24To12 } from '../utils/DateTimeUtil'

@Pipe({
    name: 'time12format'
})
export class Time12FormatPipe implements PipeTransform {
    transform(createdDate: any) {
        let date = new Date(createdDate);
        return time24To12(date.getHours() + ":" + date.getMinutes());
    }
}
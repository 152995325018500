import { Injectable } from '@angular/core';
import { LoginRequest } from '../data/loginrequest'
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { Connection } from '../data/Connection';

@Injectable()
export class AppService {
  constructor(private authService: AuthService) { }

  userHiddinLogin(request: LoginRequest): Observable<any> {
    request.password = window.btoa(request.password);
    var path = 'oauth/token?requestPath=appLogin';
    return this.authService.postDefaultEncryptNoSecure(path, request);
  }

  autoLogin(authToken: string): Observable<any> {
    var path = 'oauth/token?requestPath=autoLogin&token=' + authToken;
    return this.authService.postDefaultEncryptNoSecure(path, {});
  }

  loadChatMember(): Observable<any> {
    return this.authService.get('user/chatMembers?page=0&size=25') as Observable<any>;
  }

  loadConnection(): Observable<any> {
    return this.authService.get('user/connections?page=0&size=25') as Observable<any>;
  }

  addToChatMember(connection: Connection): Observable<any> {
    return this.authService.postEncrypt('user/addChatMember', connection) as Observable<any>;
  }

  syncDataFromHiddin(): Observable<any> {
    return this.authService.post('syncDataFromHiddin', null) as Observable<any>;
  }

  loadAppConfig(): Observable<any> {
    return this.authService.get('ns/app/config') as Observable<any>;
  }
}

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppDataService } from './appdata.service';
import { Message } from '../data/message';
import { isEmpty, nonEmpty } from '../utils/CommonUtil';
import { EncryptionUtil } from '../utils/EncryptionUtil';
import { AppConfig } from '../data/AppConfig';
import { Globals } from '../globals';

@Injectable()
export class WebSocketService {

  websocket: WebSocket;

  constructor(private appData: AppDataService,
    private globals: Globals) {
  }

  initiate(): WebSocket {
    this.websocket = new WebSocket(environment.WEBSOCKET_URL + "?token=Bearer " + this.appData.hiddinToken);
    return this.websocket;
  }

  openConnection = (callback: Function) => {
    this.websocket.onopen = (event: any) => {
      callback();
    }
  }

  startListening = (callback: Function) => {
    this.websocket.onmessage = (event: MessageEvent) => {
      callback(event);
    };
  }

  isWebSocketOpen = () => { return nonEmpty(this.websocket) && this.websocket.readyState === this.websocket.OPEN }
  isWebSocketConnecting = () => { return nonEmpty(this.websocket) && this.websocket.readyState === this.websocket.CONNECTING }
  isWebSocketClosed = () => { return nonEmpty(this.websocket) && (this.websocket.readyState === this.websocket.CLOSING || this.websocket.readyState === this.websocket.CLOSED) }

  sendMessage(msg: Message) {
    if (isEmpty(msg)) return;

    // console.log("Websocket readState: ", this.websocket.readyState);

    if (!this.isWebSocketOpen()) {
      // this.initializeWebsocket();
      return;
    }

    let decryptedMsg = msg.message;
    console.log("websocket", msg);
    // Encrypt message text
    if (nonEmpty(msg.message)) {
      msg.message = EncryptionUtil.encrypt(this.appData.commKey.aesKey, msg.message as string);
    }

    // Default encryption will be used here because both sender and reciever should not known tehe keys of each other
    var encMessage = EncryptionUtil.encryptDefault(this.globals.pop, JSON.stringify(msg));

    // this.websocket.send(JSON.stringify(msg));
    this.websocket.send(encMessage);

    msg.message = decryptedMsg;
  }

}
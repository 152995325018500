// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  isEncryptionEnable: true,
  SERVER_BASE_URL: "https://vteam.live:8444/chat/",
  WEBSOCKET_URL: 'wss://vteam.live:8444/chat/websocket',
  directoryUrl: "https://hiddin.net/directory-dashbord-new",
  homeUrl: "https://vteam.live/",
  SOCKET_IO_URL: 'https://vtour.live:8085/',
  VIDEO_DEBUG_LEVEL: "all",
  loggingEnabled: true
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { Component } from '@angular/core';
import { ModalService } from './service/modal.service';
import { DataService } from './service/data.service';
import { isEmpty } from './utils/CommonUtil';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'chat-ui';
  errorMessage: any;
  isModalOpen: boolean = false;

  constructor(private modalService: ModalService,
    private dataService: DataService) {

  }

  ngAfterViewInit() {
  }

  readErrorMessage() {
    if (!isEmpty(this.dataService.errorMessage) && !this.isModalOpen) {
      this.errorMessage = this.dataService.errorMessage;
      this.openAlertPopup();
    }
    return this.errorMessage;
  }

  openAlertPopup() {
    this.isModalOpen = true;
    this.modalService.open('alert-modal');
  }

  closeModal() {
    this.isModalOpen = false;
    this.dataService.errorMessage = "";
    this.modalService.close('alert-modal');
  }
}

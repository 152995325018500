export class CommunicationKey {
    communicationId: string;
    aesKey: string;
    commIdVsAesKey: string;

    constructor(communicationId: string, aesKey: string, commIdVsAesKey: string) {
        this.communicationId = communicationId;
        this.aesKey = aesKey;
        this.commIdVsAesKey = commIdVsAesKey;
    }
}
<h1>Dashboard</h1>
<p>
    <a [routerLink]="['/login']">Login</a>
</p>
<p>
    <a [routerLink]="['/chat/0']">Chat</a>
</p>
<p>
    <a [routerLink]="['/connection']">Connections</a>
</p>

<div class="col-md-6 col-md-offset-3">
    <button class="btn btn-primary" (click)="openModal('custom-modal-1')">Open Modal 1</button>
    <button class="btn btn-primary" style="margin-left: 2%;" (click)="openModal('custom-modal-2')">Open Modal 2</button>
</div>

<custom-modal id="custom-modal-1">
    <div class="modal width-70">
        <div class="modal-body">
            <h1>A Custom Modal!</h1>
            <p>
                Home page text: <input type="text" [(ngModel)]="bodyText" />
            </p>
            <button (click)="closeModal('custom-modal-1');">Close</button>
        </div>
    </div>
    <div class="modal-background"></div>
</custom-modal>

<custom-modal id="custom-modal-2">
    <div class="modal width-70">
        <div class="modal-body">
            <h1>A Tall Custom Modal!</h1>
            <button (click)="closeModal('custom-modal-2');">Close</button>
        </div>
    </div>
    <div class="modal-background"></div>
</custom-modal>
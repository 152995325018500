import { Injectable } from '@angular/core';
import { XHRHandler } from './xhrhandler.service';
import { Observable } from 'rxjs';
import { pagination } from '../utils/CommonUtil';

@Injectable()
export class MessageService {
    constructor(private xhrhandler: XHRHandler) { }

    loadUserChat(senderId: Number, receiverId: Number, page: Number, size: Number): Observable<any> {
        return this.xhrhandler.doGetWithAutHeader('message/' + senderId + '/' + receiverId + pagination(page, size));
    }
}
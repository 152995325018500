import { Injectable } from '@angular/core';
import { XHRHandler } from './xhrhandler.service';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class CommunicationService {
    constructor(private xhrhandler: XHRHandler,
        private authService: AuthService) { }

    fetchCommunicationKey(): Observable<any> {
        return this.xhrhandler.doGetWithAutHeader('communication?requestPath=getCurrentAESKey') as Observable<any>;
    }

    fetchAesKeysForCommIds(commIds: string) {
        return this.xhrhandler.doGetWithAutHeader(`communication?requestPath=getAESKeysForCommunicationId&commId=` + commIds) as Observable<any>;
    }
}
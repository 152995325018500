<router-outlet></router-outlet>
<custom-modal id="alert-modal">
    <div class="modal">
        <div class="modal-dialog">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">Error!</h4>
                    <button type="button" class="close" (click)="closeModal()">&times;</button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    {{readErrorMessage()}}
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" (click)="closeModal()">Close</button>
                </div>

            </div>
        </div>
    </div>
    <div class="modal-background"></div>
</custom-modal>